import { actionTypes } from '../types'

const initState = {
    data: []
};

const teeTimePostingReducer = (state = initState, action) => {

    switch (action.type) {
        case actionTypes.TEE_POSTINGS: {
            let newState = {};
            newState = {
                data: action.payload.meta,
                taxInclusiveOnFee: action.payload.meta[0].taxInclusiveOnFee,
                taxPercent: action.payload.meta[0].taxPercent,
                includeTax: action.payload.meta[0].includeTax,
            }
            return newState
        }
        case actionTypes.TEE_POSTINGS_NONE: {
            let newState = {};
            newState = {
                data: []
            }
            return newState
        }
        default:
            {
                return state
            }

    }

}

export default teeTimePostingReducer
