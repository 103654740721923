import { actionTypes } from '../types'

const initState = {
    postingMeta: {},
    eventMeta: {},
    totalChargeAmount: 0,
    totalCartProcessingFees: 0,
    totalTicketsRequested: 0,
    totalCartTax: 0,
    selectedTicketsInfo: [],
    cards: []
};

const cartReducer = (state = initState, action) => {

    switch (action.type) {
        case actionTypes.SET_CART_INFO: {
            let newState = {};
            newState = {
                ...state,
                totalChargeAmount: action.payload.totalChargeAmount,
                totalCartProcessingFees: action.payload.totalCartProcessingFees,
                totalTicketsRequested: action.payload.totalTicketsRequested,
                totalCartTax: action.payload.totalCartTax
                //selectedTicketsInfo: action.payload.selectedTicketsInfo
            }
            return newState
        }

        case actionTypes.ADD_NEW_PLAYER: {
            return {
                ...state,
                selectedTicketsInfo: [
                    ...state.selectedTicketsInfo,
                    action.payload.newPlayer
                ]
            }
        }
        case actionTypes.UPDATE_PLAYER: {
            return {
                ...state,
                selectedTicketsInfo: [
                    ...state.selectedTicketsInfo.slice(0, action.payload.index),
                    action.payload.updatedPlayerInfo,
                    ...state.selectedTicketsInfo.slice(action.payload.index + 1),

                ]
            }
        }
        case actionTypes.REMOVE_PLAYER: {
            return {
                ...state,
                selectedTicketsInfo: [
                    ...state.selectedTicketsInfo.slice(0, action.payload.index),
                    ...state.selectedTicketsInfo.slice(action.payload.index + 1),

                ]
            }
        }
        case actionTypes.CARDS_INFO: {
            return {
                ...state,
                cards: action.payload.cards,
                cardDefault: action.payload.default_source
            }
        }
        case actionTypes.CLEAR_CART_INFO: {
            return {
                postingMeta: {},
                eventMeta: {},
                totalChargeAmount: 0,
                totalCartProcessingFees: 0,
                totalTicketsRequested: 0,
                totalCartTax: 0,
                selectedTicketsInfo: [],
                cards: []
            }
        }
        case actionTypes.ADD_COUPON_TO_CART: {
            return {
                ...state,
                coupon: action.payload.couponResp
            }
        }
        /*case actionTypes.GET_MEMBERSHIP_BENEFITS: {

            let player = {
                ...state.selectedTicketsInfo[action.payload.index],
                player: {
                    ...state.selectedTicketsInfo[action.payload.index].player,
                    name: action.payload.name,
                    memberType: action.payload.memberBenefits.validMember ? 'Member' : 'Guest',
                    cannotMatchName: action.payload.memberBenefits.validMember ? false : true
                }
            }

            return {
                ...state,
                selectedTicketsInfo: [
                    ...state.selectedTicketsInfo.slice(0, action.payload.index),
                    player,
                    ...state.selectedTicketsInfo.slice(action.payload.index + 1),

                ]
            }
        } */
        case actionTypes.GET_EVENT_DATA: {
            return {
                ...state,
                eventMeta: action.payload.eventInfo
            }
        }
        case actionTypes.GET_POSTING_INFO: {
            return {
                ...state,
                postingMeta: action.payload.postingMeta
            }
        }
        default:
            {
                return state
            }

    }

}

export default cartReducer
