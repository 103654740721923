import React, { Component, Fragment, useState, useCallback } from 'react'

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { withStyles } from '@material-ui/core/styles';
import TeeSlot from './teeSlot'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import classnames from 'classnames'
import ToggleButton from './toggleButton'

import debounce from '../../hooks/debounce'

const styles = (theme) => ({
    root: {
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(10),
        },
    },
    indicator: {
        backgroundColor: 'transparent',
    },
    tabRoot: {
        minWidth: '100px',
        padding: 0,
        backgroundColor: 'white',
        marginLeft: theme.spacing(1),
        color: '#3F3F3F',

    },
    checkoutContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: theme.spacing(4)

    },
    header: {
        fontSize: theme.spacing(3),
        fontWeight: 700,
        color: theme.palette.text.primary,
        textTransform: 'uppercase'
    },
    marginBottom3: {
        marginBottom: theme.spacing(3)
    },
    marginBottom4: {
        marginBottom: theme.spacing(4)
    },
    marginBottom5: {
        marginBottom: theme.spacing(5)
    },
    marginBottom6: {
        marginBottom: theme.spacing(6)
    },
    topContainer: {
        width: '100%',
        paddingBottom: theme.spacing(5),
    },
    playersContainer: {
        marginLeft: theme.spacing(2)
    },
    padding2: {
        padding: theme.spacing(2)
    },
    playerContainer: {
        marginTop: theme.spacing(2)
    },
    marginTop3: {
        marginTop: theme.spacing(3)
    },
    marginTop8: {
        marginTop: theme.spacing(8)
    },
    marginTop5: {

        marginTop: theme.spacing(5)
    },
    marginTop2: {
        marginTop: theme.spacing(2)
    },
    button: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        letterSpacing: '1px',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: 'transparent'
        }
    },
    buttonPay: {
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: theme.palette.primary.main
        }
    },
    buttonCustomDashed: {
        color: '#009BE1',
        background: 'white',
        fontWeight: 600,
        border: '2px dashed #009BE1'

    },
    marginBottom2: {
        marginBottom: theme.spacing(3)
    },
    marginBottom1: {
        marginBottom: theme.spacing(1)
    },
    bold: {
        fontWeight: '600'
    },
    muted: {
        color: theme.palette.text.disabled
    },
    toggle: {
        "& .MuiToggleButton-root": {
            border: 0,
            color: theme.palette.text.primary,
            background: theme.palette.background.default,
            minWidth: theme.spacing(10),
            textTransform: 'capitalize',
            padding: '6px'
        },
        "& .Mui-selected": {
            backgroundColor: theme.palette.primary.main + ' !important',
            color: 'white',
            fontWeight: 600
        }
    },
    bgTransparent: {
        background: 'transparent'
    },
    paddingLeft12: {
        paddingLeft: '12px'
    },
    [theme.breakpoints.down('md')]: {
        padding2MdDown: {
            padding: theme.spacing(2)
        }


    }

});



const PayDecision = ({ classes, payNowDefaultValue, onChangeEmailForInvoice, index, payLaterEmail, onChangePayNow }) => {

    const [payLater, setpayLater] = useState(payNowDefaultValue)

    const onPaySelect = (val) => {
        setpayLater(val);
        onChangePayNow(!val, index)
    }

    const onChangeEmail = debounce((email) => {

        onChangeEmailForInvoice(email, index);
        //  onChangeEmailForSendingInvoice(e.target.value, index);


    }, 1000)

    return <div className={classes.padding2MdDown}>

        <Grid container spacing={4} alignItems="center">
            <Grid item xs={3} md={3} lg={2}><Paper className={classes.muted}>Pay Now? </Paper></Grid>
            <Grid item xs><Paper className={classnames(classes.bgTransparent, classes.paddingLeft12)}><div className={classes.toggle}><ToggleButton yesLabel="Yes" noLabel="No" value={payNowDefaultValue} onSelect={onPaySelect}></ToggleButton></div></Paper></Grid>
        </Grid>
        {payLater && <Grid container spacing={3} alignItems="center">
            <Grid item xs={2}><Paper className={classes.muted}>Email For Payment</Paper></Grid>
            <Grid item xs><Paper><TextField defaultValue={payLaterEmail} onChange={(e) => onChangeEmail(e.target.value)} InputProps={{ disableUnderline: true, type: 'email' }} id="filled-basic" variant="filled" placeholder="Enter email for sending invoice" /></Paper></Grid>
        </Grid>}
    </div>

}


export default withStyles(styles)(PayDecision)