export const constants = {
    environment: 'production',
    version: '1.0',
    apiUrl: 'https://api.allcal.com',
    dashboardBaseUrl: 'https://api.dashboard.allcal.com',
    ticketsBaseUrl: 'https://api.tickets.allcal.com',
    ticketsBaseUrlAlt: 'https://purchase.tickets.allcal.com', // has to be purchase.allcal.com for prod
    appBaseUrl: 'https://app.allcal.com',
    templatesBaseUrl: 'http://templates.allcal.com',
    templatesBaseUrlHttps: 'https://templates.allcal.com',
    localhost: 'http://localhost:1338',
    //selectedOrg: "5b2d6803bd2fdd24d0416125",
    //selectedCalendar: "5ed66ba4c7a3244931b42b78",
    selectedOrg: '5aac690103b3460db2a17ba9',
    selectedCalendar: '5aac6cbde884610de6ab5107',
    paypalEnv: 'production',
    paypalClient: {
        sandbox: 'AZRsm7FABGzUjcmoxm8lgYSY9kxh9VhL4W5ac-kQYVnb5cln9yCpfEfTg8qfpAVq9UPLYwE32_a8FLBF',
        production: 'AZ4h4j5fzzpDGByfxZ5GMQxyIOwc9Cpkjpqv7-tpqnDHv51TOT4MwSbFBMoZhN4ZfBzEiYtcKJwvv15h',
    }
};
