import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './store/reducers/rootReducer';
import thunk from 'redux-thunk';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { SnackbarProvider } from 'notistack';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_btebNtjR3AQlGUFYNar7CdS5');
//const stripePromise = loadStripe('pk_test_mpkPY0W4O03fIWaq3zb691oE');


const theme = createMuiTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif;",
    useNextVariants: true
  },
  shadows: Array(25).fill('none'),
  palette: {
    primary: {
      main: "#009be1",
      dark: "#0075B8",
      light: '#b6c0f2',
      default: '#000000de'
    },
    secondary: {
      main: "#10b215"
    },

    background: {
      paper: '#FFF',
      default: '#f7f9fa',
      label: '#dfeaf0',
      note: '#ffee9d'
    },
    error: {
      main: '#CD5C5C'
    },
    success: {
      main: '#10b215'
    },
    text: {
      primary: '#282c34',
      secondary: '#0075b8',
      disabled: '#A4A6A7',
      highlight: '#127AB3'
    },

  },
  overrides: {

    MuiFormControl: {
      root: {
        display: 'flex'
      }
    },

    MuiFilledInput: {
      input: {
        padding: "12px 12px"

      },
      root: {
        backgroundColor: '#F7F9FA',
        '&:active': {
          backgroundColor: '#e8e9eadb !important',
        },
        '&:hover': {
          backgroundColor: '#e8e9eadb !important',
        }

      }
    },
    MuiTextField: {
      root: {
        padding: "6px 12px"

      },
    }
  }

});

//Can add saga later if needed
const middleWares = [thunk]

const store = createStore(rootReducer, applyMiddleware(...middleWares));

ReactDOM.render(
  <React.StrictMode>
    {/*basename="/projects/app-templates/rb-may/teetimes/" */}
    <BrowserRouter><MuiThemeProvider theme={theme}><StyledThemeProvider theme={theme}><Provider store={store}> <Elements stripe={stripePromise}><SnackbarProvider><App /></SnackbarProvider></Elements></Provider></StyledThemeProvider></MuiThemeProvider></BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
