import React from 'react';

import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { Route, Switch } from 'react-router-dom';
import BookTee from '../src/components/bookTee'
import AddPlayers from '../src/components/addPlayers'
import './App.css';
import payNow from './components/payNow';
import myAccount from './components/myAccount';
import Success from './components/success'
import UserSuccess from './components/userSuccess'
import hoc from './components/hoc/protectedRoute'
import Register from './components/ui/RegisterComponent'

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={hoc(BookTee)} />
        <Route exact path="/teetimes/" component={hoc(BookTee)} />
        <Route exact path="/players/:eventId/book/:postingId" component={hoc(AddPlayers)} />
        <Route exact path="/pay" component={hoc(payNow)} />
        <Route exact path="/account" component={myAccount} />
        <Route exact path="/event/:eventId/guest/:guestId/charge/:chargeId/success" component={Success} />
        <Route exact path="/event/:eventId/trans/:transId/charge/:chargeId/success" component={UserSuccess} />
        <Route exact path="/register" component={Register} />
      </Switch>
    </div>
  );
}

export default withRouter(App);
