import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { allcalUserSuccessData } from '../store/actions/cartActions'
import { QRCode } from "react-qr-svg";

const styles = (theme) => ({
    root: {
        marginTop: '50px',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(10),
        },
    },
    indicator: {
        backgroundColor: 'transparent',
    },
    tabRoot: {
        minWidth: '100px',
        padding: 0,
        backgroundColor: 'white',
        marginLeft: theme.spacing(1),
        color: '#3F3F3F',

    },
    checkoutContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: theme.spacing(4)

    },
    header: {
        fontSize: theme.spacing(3),
        fontWeight: 700,
        color: theme.palette.text.primary
    },
    topContainer: {
        width: '100%',
        paddingBottom: theme.spacing(5),
    },
    playersContainer: {
        marginLeft: theme.spacing(2)
    },
    padding2: {
        padding: theme.spacing(2)
    },
    padding3: {
        padding: theme.spacing(3)
    },
    playerContainer: {
        marginTop: theme.spacing(2)
    },
    marginTop3: {
        marginTop: theme.spacing(3)
    },
    marginTop8: {
        marginTop: theme.spacing(8)
    },
    marginTop5: {

        marginTop: theme.spacing(5)
    },
    marginTop2: {
        marginTop: theme.spacing(2)
    },
    button: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        letterSpacing: '1px',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: 'transparent'
        }
    },
    buttonPay: {
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: theme.palette.primary.main
        }
    },
    buttonCustomDashed: {
        color: '#009BE1',
        background: 'white',
        fontWeight: 600,
        border: '2px dashed #009BE1'

    },
    marginBottom2: {
        marginBottom: theme.spacing(3)
    },
    marginBottom1: {
        marginBottom: theme.spacing(1)
    },
    bold: {
        fontWeight: '600'
    },
    muted: {
        color: theme.palette.text.disabled
    },
    bgTransparent: {
        backgroundColor: 'transparent'
    },
    smallText: {
        fontSize: theme.spacing(1.75)
    },
    center: {
        textAlign: 'center'
    },
    subText: {
        fontWeight: 500,
        paddingLeft: theme.spacing(2)
    }

});


class UserSuccess extends Component {
    state = {

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        // this.props.match.params.eventId;
        // this.props.match.params.guestId;
        // this.props.match.params.chargeId;
        this.props.allcalUserSuccessData(this.props.match.params.eventId, this.props.match.params.transId, this.props.match.params.chargeId, (data) => {
            if (!data.error) {
                this.setState({
                    successData: data.message
                })
            }
            console.log(data)
        })
    }

    render() {
        const { classes } = this.props;

        return <div className={classes.root}>
            <Grid container className={classes.topContainer}>
                <Grid container className={classes.marginTop5} justify="center">
                    <Grid item ><Paper className={classnames(classes.bgTransparent, classes.smallText, classes.center)} ><div style={{ marginBottom: '8px' }}>Thank you for booking your tee time with us. You can find your confirmation details below. </div><div>You will also receive an email at {this.state.successData && this.state.successData.tickets[0].currentUserEmail} with the booking details</div></Paper></Grid>
                </Grid>
            </Grid>
            <Grid container wrap='wrap' spacing={5} justify='center' >
                <Grid item md={8} lg={6} xs={12} >

                    <Grid item md xs={12} className={classes.marginTop2}>
                        <Paper className={classes.padding3}>
                            <Grid container item justify="center">
                                <div className={classes.header}>Booking confirmation</div>
                            </Grid>

                            {
                                this.state.successData && this.state.successData.tickets.map((ticket, index) => {

                                    return <Grid key={index} container justify="space-between" alignItems="center" className={classes.marginTop2}>
                                        <Grid item >
                                            <Grid container direction="column" spacing={1} >
                                                <Grid item >{ticket.player.name}</Grid>
                                                <Grid item >{ticket.title} | {this.state.successData.eventInfo.eventDate}</Grid>
                                                <Grid item >1 person</Grid>
                                                <Grid item >{this.state.successData.eventInfo.eventLocation}</Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid item>
                                            <QRCode
                                                bgColor="#FFFFFF"
                                                fgColor="#000000"
                                                level="Q"
                                                style={{ width: 125 }}
                                                value={ticket.ticketId}
                                            />
                                        </Grid>

                                    </Grid>

                                })
                            }

                            <Grid container item direction="column" spacing={1} >
                                <Grid item ><Paper className={classnames(classes.bold, classes.marginTop3)}>Total Paid - ${this.state.successData && this.state.successData.totalChargeAmount}</Paper></Grid>

                            </Grid>
                        </Paper>
                    </Grid>


                </Grid>
            </Grid>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        allcalUserSuccessData: (eventId, transId, chargeId, cb) => dispatch(allcalUserSuccessData(eventId, transId, chargeId, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserSuccess))