import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import Buttom from '@material-ui/core/Button'
import SpecialCrumb from './specialCrumb'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => (styles(theme)));

const styles = (theme) => {

    return ({
        topSection: {
            padding: theme.spacing(2)
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
        paddingTopBottom: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        },
        link: {
            textDecoration: 'none',
            width: '100%'
        }
    })

}

export default function SimpleCard({ slot, availability, price, bookSlotClicked, posting, isSpecial, specialText, specialDesc, event }) {
    const classes = useStyles();
    return (
        <Grid container item direction="row" xs={12} sm={4} md={3} lg={2} style={{ margin: '10px', padding: '10px', backgroundColor: parseInt(availability) > 0 ? 'white' : 'transparent' }}>
            <Grid container item justify="space-between" spacing={2} className={classes.paddingTopBottom}>
                <Grid item style={{ fontWeight: '500', color: parseInt(availability) > 0 ? 'initial' : '#939494' }}>{slot}</Grid>
                {parseInt(availability) > 0 && <Grid item style={{ color: '#46B649' }} >{parseInt(availability) + ' Available'}</Grid>}

            </Grid>
            <Grid container item justify="center" alignItems="center" direction="column" spacing={2} className={classes.paddingTopBottom}>
                {parseInt(availability) > 0 && <Grid item style={{ fontWeight: '700' }}>{price}</Grid>}
                {isSpecial && <Grid item><SpecialCrumb specialText={specialText} specialDesc={specialDesc}></SpecialCrumb></Grid>}
                {parseInt(availability) == 0 && <Grid item style={{ color: 'red' }} >SOLD OUT</Grid>}

            </Grid>

            <Grid container item justify="center" alignItems="flex-end" className={classes.paddingTopBottom}>
                <Link to={'/players/' + event + '/book/' + posting} className={classes.link}>
                    <Button disabled={parseInt(availability) == 0} fullWidth variant="contained" color="primary" onClick={bookSlotClicked}>
                        Book Now
                    </Button>
                </Link>

            </Grid>
        </Grid>


    );
}