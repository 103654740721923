import { dispatchErrorWrap, dispatchSuccessWrap } from './genericActions'

import { constants } from '../../App.constants';
import { actionTypes } from '../types'

import moment from 'moment'
const axios = require('axios');

export const getTicketPostingInfo = (postingId, cb) => {
    return async (dispatch) => {
        let header = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        try {

            const postingMeta = await axios.get(constants.ticketsBaseUrl + "/posting/" + postingId + "/postingMeta", header);
            if (postingMeta.data && postingMeta.data.meta) {
                dispatchSuccessWrap(actionTypes.GET_POSTING_INFO, { postingMeta: postingMeta.data.meta }, dispatch);
                cb(postingMeta.data.meta)
            }


        } catch (err) {
            console.log(err)
            dispatchErrorWrap(actionTypes.ERROR, dispatch);

        }

    }
}

export const getEventData = (eventId) => {
    return async (dispatch) => {
        let header = {
            headers: {
                'Content-Type': 'application/json',
                'access-token': '3207498533.1677ed0.6fe195488f934a3f9fc2bd1053ea8356'
            }
        }
        try {

            const eventInfo = await axios.get(constants.apiUrl + "/events/" + eventId, header);
            if (eventInfo.data) {
                dispatchSuccessWrap(actionTypes.GET_EVENT_DATA, { eventInfo: eventInfo.data }, dispatch);

            }


        } catch (err) {
            console.log(err)
            dispatchErrorWrap(actionTypes.ERROR, dispatch);

        }

    }
}

export const checkIfValidMemberByName = (name, cb) => {
    return async (dispatch) => {
        let header = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        try {

            const memberBenefits = await axios.get(constants.ticketsBaseUrl + "/organization/" + constants.selectedOrg + "/memName?email=" + name, header);
            if (memberBenefits.data) {
                dispatchSuccessWrap(actionTypes.GET_MEMBERSHIP_BENEFITS, { memberBenefits: memberBenefits.data }, dispatch);
                cb(memberBenefits.data);
            }


        } catch (err) {
            console.log(err)
            dispatchErrorWrap(actionTypes.ERROR, dispatch);

        }

    }
}


export const checkIfValidMemberByEmail = (name, cb) => {
    return async (dispatch) => {
        let header = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        try {

            const memberBenefits = await axios.get(constants.ticketsBaseUrl + "/organization/" + constants.selectedOrg + "/mem?email=" + encodeURIComponent(name), header);
            if (memberBenefits.data) {
                dispatchSuccessWrap(actionTypes.GET_MEMBERSHIP_BENEFITS, { memberBenefits: memberBenefits.data }, dispatch);
                cb(memberBenefits.data);
            }


        } catch (err) {
            console.log(err)
            dispatchErrorWrap(actionTypes.ERROR, dispatch);

        }

    }
}

export const getLoggedInUserCustInfoFromSellerStripe = (cb) => {
    return async (dispatch) => {

        let header = {
            headers: {
                'Content-Type': 'application/json',
                'login-token': localStorage.getItem('loginToken')
            }
        }
        try {

            const customerData = await axios.get(constants.dashboardBaseUrl + "/organization/" + constants.selectedOrg + "/user/" + localStorage.getItem('userId') + "/stripe/customer", header);
            if (customerData.data && customerData.data.data && customerData.data.data.sources) {
                dispatchSuccessWrap(actionTypes.CARDS_INFO, { cards: customerData.data.data.sources.data, default_source: customerData.data.data.default_source }, dispatch);
                cb(customerData.data)
            }


        } catch (err) {
            console.log(err)
            dispatchErrorWrap(actionTypes.ERROR, dispatch);

        }


    }
}

export const guestPaypalPurchase = (name, email, cart, eventId, paypalChargeData, cb) => {

    return async (dispatch) => {
        if (email && email != '') {
            let header = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            let data = {};

            data = {
                "email": email,
                "cart": cart,
                "eventId": eventId,
                "calendarId": constants.selectedCalendar,
                "ticketType": "paypal",
                "paypalChargeData": paypalChargeData
            }
            try {

                const chargeResponse = await axios.post(constants.ticketsBaseUrl + "/organization/" + constants.selectedOrg + "/chargeTee", data, header);
                if (!chargeResponse.data.error) {

                    if (!name || name === '') {
                        name = email;
                    }
                    let text = name + ' purchased ' + cart.totalTicketsRequested + ' tee time'
                    if (cart.totalTicketsRequested > 1) {
                        text = text + 's'
                    }
                    text = text + ' online - ' + moment(cart.eventMeta.startDate).format('MMM D') + ' ' + cart.postingMeta.title
                    let historyData = {
                        notificationText: text
                    }
                    await axios.post(constants.dashboardBaseUrl + "/organization/" + constants.selectedOrg + "/portalHistory", historyData, header)


                    dispatchSuccessWrap(actionTypes.GUEST_PURCHASE, { chargeResponse: chargeResponse.data.message }, dispatch);
                    cb(null, chargeResponse.data.message);
                } else {
                    cb('Error processing payment ' + chargeResponse.data.message);
                }

            } catch (err) {
                console.log(err)
                dispatchErrorWrap(actionTypes.ERROR, dispatch);

            }

        }


    }

}

export const allcalPaypalPurchase = (name, userIdToConsider, cart, eventId, paypalChargeData, cb) => {
    return async (dispatch) => {
        // Member and paying
        if (userIdToConsider) {
            let header = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': localStorage.getItem('loginToken')
                }
            }
            let data = {};
            data = {
                "buyerId": userIdToConsider,
                "cart": cart,
                "eventId": eventId,
                "calendarId": constants.selectedCalendar,
                "ticketType": "paypal",
                "paypalChargeData": paypalChargeData
            }


            try {

                const chargeResponse = await axios.post(constants.ticketsBaseUrl + "/organization/" + constants.selectedOrg + "/chargeTee", data, header);
                if (!chargeResponse.data.error) {
                    let text = name + ' purchased ' + cart.totalTicketsRequested + ' tee time'
                    if (cart.totalTicketsRequested > 1) {
                        text = text + 's'
                    }
                    text = text + ' online - ' + moment(cart.eventMeta.startDate).format('MMM D') + ' ' + cart.postingMeta.title
                    let historyData = {
                        notificationText: text
                    }
                    await axios.post(constants.dashboardBaseUrl + "/organization/" + constants.selectedOrg + "/portalHistory", historyData, header)



                    dispatchSuccessWrap(actionTypes.ALLCAL_USER_PURCHASE, { chargeResponse: chargeResponse.data.message }, dispatch);
                    cb(null, chargeResponse.data.message);
                } else {
                    cb('Error processing payment ' + chargeResponse.data.message);
                }

            } catch (err) {
                console.log(err)
                dispatchErrorWrap(actionTypes.ERROR, dispatch);

            }

        }

    }
}


export const allcalUserPurchase = (name, userIdToConsider, stripeToken, cart, eventId, ticketType, cb) => {
    return async (dispatch) => {
        // Member and paying
        if (userIdToConsider) {
            let header = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': localStorage.getItem('loginToken')
                }
            }
            let data = {};
            if (stripeToken && ticketType == 'paid') {
                data = {
                    "buyerId": userIdToConsider,
                    "stripeToken": stripeToken,
                    "cart": cart,
                    "eventId": eventId,
                    "calendarId": constants.selectedCalendar,
                    "ticketType": "paid"
                }
            } else {
                data = {
                    "buyerId": userIdToConsider,
                    "cart": cart,
                    "eventId": eventId,
                    "calendarId": constants.selectedCalendar,
                    "ticketType": "free"
                }
            }

            try {

                const chargeResponse = await axios.post(constants.ticketsBaseUrl + "/organization/" + constants.selectedOrg + "/chargeTee", data, header);
                if (!chargeResponse.data.error) {
                    let text = name + ' purchased ' + cart.totalTicketsRequested + ' tee time'
                    if (cart.totalTicketsRequested > 1) {
                        text = text + 's'
                    }
                    text = text + ' online - ' + moment(cart.eventMeta.startDate).format('MMM D') + ' ' + cart.postingMeta.title
                    let historyData = {
                        notificationText: text
                    }
                    await axios.post(constants.dashboardBaseUrl + "/organization/" + constants.selectedOrg + "/portalHistory", historyData, header)



                    dispatchSuccessWrap(actionTypes.ALLCAL_USER_PURCHASE, { chargeResponse: chargeResponse.data.message }, dispatch);
                    cb(null, chargeResponse.data.message);
                } else {
                    cb('Error processing payment ' + chargeResponse.data.message);
                }

            } catch (err) {
                console.log(err)
                dispatchErrorWrap(actionTypes.ERROR, dispatch);

            }

        }

    }
}

export const allcalUserPurchaseWithSelectedCard = (name, buyerId, selectedCard, cart, eventId, ticketType, cb) => {

    return async (dispatch) => {
        // Member and paying
        if (buyerId) {
            let header = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': localStorage.getItem('loginToken')
                }
            }
            let data = {};
            if (selectedCard && ticketType == 'paid') {
                data = {
                    "buyerId": buyerId,
                    "cardId": selectedCard,
                    "cart": cart,
                    "eventId": eventId,
                    "calendarId": constants.selectedCalendar,
                    "ticketType": "paid"
                }
            }

            try {

                const chargeResponse = await axios.post(constants.ticketsBaseUrl + "/organization/" + constants.selectedOrg + "/chargeTee", data, header);
                if (!chargeResponse.data.error) {
                    let text = name + ' purchased ' + cart.totalTicketsRequested + ' tee time'
                    if (cart.totalTicketsRequested > 1) {
                        text = text + 's'
                    }
                    text = text + ' online - ' + moment(cart.eventMeta.startDate).format('MMM D') + ' ' + cart.postingMeta.title
                    let historyData = {
                        notificationText: text
                    }
                    await axios.post(constants.dashboardBaseUrl + "/organization/" + constants.selectedOrg + "/portalHistory", historyData, header)


                    dispatchSuccessWrap(actionTypes.ALLCAL_USER_PURCHASE, { chargeResponse: chargeResponse.data.message }, dispatch);
                    cb(null, chargeResponse.data.message);
                } else {
                    cb('Error processing payment ' + chargeResponse.data.message);
                }

            } catch (err) {
                console.log(err)
                dispatchErrorWrap(actionTypes.ERROR, dispatch);

            }

        }

    }

}

export const guestPurchase = (name, email, stripeToken, cart, eventId, ticketType, cb) => {

    return async (dispatch) => {
        if (email && email != '') {
            let header = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            let data = {};
            if (ticketType == 'paid') {
                data = {
                    "email": email,
                    "stripeToken": stripeToken,
                    "cart": cart,
                    "eventId": eventId,
                    "calendarId": constants.selectedCalendar,
                    "ticketType": "paid"
                }
            } else {
                data = {
                    "email": email,
                    "cart": cart,
                    "eventId": eventId,
                    "calendarId": constants.selectedCalendar,
                    "ticketType": "free"
                }
            }

            try {

                const chargeResponse = await axios.post(constants.ticketsBaseUrl + "/organization/" + constants.selectedOrg + "/chargeTee", data, header);
                if (!chargeResponse.data.error) {

                    if (!name || name === '') {
                        name = email;
                    }
                    let text = name + ' purchased ' + cart.totalTicketsRequested + ' tee time'
                    if (cart.totalTicketsRequested > 1) {
                        text = text + 's'
                    }
                    text = text + ' online - ' + moment(cart.eventMeta.startDate).format('MMM D') + ' ' + cart.postingMeta.title
                    let historyData = {
                        notificationText: text
                    }
                    await axios.post(constants.dashboardBaseUrl + "/organization/" + constants.selectedOrg + "/portalHistory", historyData, header)


                    dispatchSuccessWrap(actionTypes.GUEST_PURCHASE, { chargeResponse: chargeResponse.data.message }, dispatch);
                    cb(null, chargeResponse.data.message);
                } else {
                    cb('Error processing payment ' + chargeResponse.data.message);
                }

            } catch (err) {
                console.log(err)
                dispatchErrorWrap(actionTypes.ERROR, dispatch);

            }

        }


    }

}


export const guestSuccessEmail = (eventId, guestId, chargeId, cart, cb) => {

    return async (dispatch) => {
        let header = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        const data = {
            "cartInfo": cart,
        }
        try {

            const sucessEmailResponse = await axios.post(constants.ticketsBaseUrl + "/calendar/" + constants.selectedCalendar + "/event/" + eventId + "/guest/" + guestId + "/tees/charge/" + chargeId + "/email", data, header);
            if (sucessEmailResponse.data) {
                dispatchSuccessWrap(actionTypes.GUEST_PURCHASE_EMAIL, { sucessEmailResponse: sucessEmailResponse.data }, dispatch);
                cb(null, sucessEmailResponse.data);
            } else {
                cb('Error processing email');
            }

        } catch (err) {
            console.log(err)
            dispatchErrorWrap(actionTypes.ERROR, dispatch);

        }

    }

}


export const allcalUserSuccessEmail = (email, eventId, transId, chargeId, cart, cb) => {

    return async (dispatch) => {
        let header = {
            headers: {
                'Content-Type': 'application/json',
                'login-token': localStorage.getItem('loginToken')
            }
        }

        const data = {
            "cartInfo": cart,
        }
        try {

            const sucessEmailResponse = await axios.post(constants.ticketsBaseUrl + "/calendar/" + constants.selectedCalendar + "/event/" + eventId + "/trans/" + transId + "/tees/charge/" + chargeId + "/email/" + email, data, header);
            if (sucessEmailResponse.data) {
                dispatchSuccessWrap(actionTypes.ALLCAL_PURCHASE_EMAIL, { sucessEmailResponse: sucessEmailResponse.data }, dispatch);
                cb(null, sucessEmailResponse.data);
            } else {
                cb('Error processing email');
            }

        } catch (err) {
            console.log(err)
            dispatchErrorWrap(actionTypes.ERROR, dispatch);

        }

    }

}

export const guestSuccessData = (eventId, guestId, chargeId, cb) => {

    return async (dispatch) => {
        let header = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        try {

            const successData = await axios.get(constants.ticketsBaseUrl + "/calendar/" + constants.selectedCalendar + "/event/" + eventId + "/guest/" + guestId + "/tees/charge/" + chargeId + "/v2", header);
            if (successData.data) {
                dispatchSuccessWrap(actionTypes.SUCCESS_DATA, { successData: successData.data }, dispatch);
                cb(successData.data)
            }


        } catch (err) {
            console.log(err)
            dispatchErrorWrap(actionTypes.ERROR, dispatch);

        }

    }

}

export const allcalUserSuccessData = (eventId, transId, chargeId, cb) => {

    return async (dispatch) => {
        let header = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        try {

            const successData = await axios.get(constants.ticketsBaseUrl + "/calendar/" + constants.selectedCalendar + "/event/" + eventId + "/trans/" + transId + "/tees/charge/" + chargeId + "/user/" + localStorage.getItem('loginToken') + "/v2", header);
            if (successData.data) {
                dispatchSuccessWrap(actionTypes.SUCCESS_DATA, { successData: successData.data }, dispatch);
                cb(successData.data)
            }


        } catch (err) {
            console.log(err)
            dispatchErrorWrap(actionTypes.ERROR, dispatch);

        }

    }

}

export const setCartInfo = (totalCartProcessingFees, totalChargeAmount, totalTicketsRequested, totalCartTax, cb) => {
    return (dispatch) => {
        dispatchSuccessWrap(actionTypes.SET_CART_INFO, { totalCartProcessingFees: totalCartProcessingFees, totalChargeAmount: totalChargeAmount, totalTicketsRequested: totalTicketsRequested, totalCartTax: totalCartTax }, dispatch)
        cb();
    }
}

export const clearCartInfo = () => {
    return (dispatch) => {
        dispatchSuccessWrap(actionTypes.CLEAR_CART_INFO, {}, dispatch)

    }
}

export const addNewPlayer = (newPlayer, cb) => {
    return (dispatch) => {
        dispatchSuccessWrap(actionTypes.ADD_NEW_PLAYER, { newPlayer: newPlayer }, dispatch)
        cb()

    }
}

export const updateSelectedTicketsInfo = (updatedPlayerInfo, index) => {
    return (dispatch) => {
        dispatchSuccessWrap(actionTypes.UPDATE_PLAYER, { updatedPlayerInfo: updatedPlayerInfo, index: index }, dispatch)
    }
}

export const removeSelectedTicketsInfo = (index) => {
    return (dispatch) => {
        dispatchSuccessWrap(actionTypes.REMOVE_PLAYER, { index: index }, dispatch)
    }
}

export const getCouponInfo = (couponEntered, cb) => {
    return async (dispatch) => {

        if (couponEntered == '' || couponEntered == undefined) {
            dispatchSuccessWrap(actionTypes.ADD_COUPON_TO_CART, { couponResp: undefined }, dispatch);

            cb(true, 'Not a valid coupon')
        } else {
            let header = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            try {

                const couponResp = await axios.get(constants.ticketsBaseUrl + "/organization/" + constants.selectedOrg + "/coupons/" + couponEntered, header);
                if (couponResp.data && !couponResp.data.error) {
                    dispatchSuccessWrap(actionTypes.ADD_COUPON_TO_CART, { couponResp: couponResp.data.coupon }, dispatch);
                    cb(null, couponResp.data.coupon)
                } else {
                    dispatchSuccessWrap(actionTypes.ADD_COUPON_TO_CART, { couponResp: undefined }, dispatch);

                    cb(true, 'Not a valid coupon')
                }

            } catch (err) {
                console.log(err)
                dispatchErrorWrap(actionTypes.ERROR, dispatch);

            }
        }


    }
}


