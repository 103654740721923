import React, { Component } from 'react'

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { withStyles } from '@material-ui/core/styles';
import TeeSlot from './ui/teeSlot'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import classnames from 'classnames'
import ToggleButton from './ui/toggleButton'
import PayDecision from './ui/payDecision';
import { Link } from 'react-router-dom'
import { Link as MaterialLink } from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden'
import { getTicketPostingInfo, getEventData, addNewPlayer, updateSelectedTicketsInfo, checkIfValidMemberByName, checkIfValidMemberByEmail, setCartInfo, getCouponInfo, getLoggedInUserCustInfoFromSellerStripe, removeSelectedTicketsInfo } from '../store/actions/cartActions'
import { getTeeTimePostingsMeta } from '../store/actions/teeTimePostingActions'
import moment from 'moment'
import debounce from '../hooks/debounce'

import { withSnackbar } from 'notistack';

const styles = (theme) => ({
    root: {
        background: theme.palette.background.default,
        marginTop: '50px',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0),
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(10),
            paddingBottom: theme.spacing(10)
        },
    },
    indicator: {
        backgroundColor: 'transparent',
    },
    tabRoot: {
        minWidth: '100px',
        padding: 0,
        backgroundColor: 'white',
        marginLeft: theme.spacing(1),
        color: '#3F3F3F',

    },
    checkoutContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: theme.spacing(4)

    },
    header: {
        fontSize: theme.spacing(3),
        fontWeight: 700,
        color: theme.palette.text.primary,
        textTransform: 'uppercase'
    },
    marginBottom3: {
        marginBottom: theme.spacing(3)
    },
    marginBottom4: {
        marginBottom: theme.spacing(4)
    },
    marginBottom5: {
        marginBottom: theme.spacing(5)
    },
    marginBottom6: {
        marginBottom: theme.spacing(6)
    },
    paddingBottom1: {
        paddingBottom: theme.spacing(1)
    },
    topContainer: {
        width: '100%',
        paddingBottom: theme.spacing(5),
    },
    playersContainer: {
        marginLeft: theme.spacing(2)
    },
    padding2: {
        padding: theme.spacing(2)
    },
    padding2MdUp: {
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2)
        }
    },
    playerContainer: {
        marginTop: theme.spacing(2)
    },
    marginTop3: {
        marginTop: theme.spacing(3)
    },
    marginTop8: {
        marginTop: theme.spacing(8)
    },
    marginTop5: {

        marginTop: theme.spacing(5)
    },
    marginTop2: {
        marginTop: theme.spacing(2)
    },
    button: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        letterSpacing: '1px',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: 'transparent'
        }
    },
    buttonPay: {
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: theme.palette.primary.main
        }
    },
    buttonCustomDashed: {
        color: '#009BE1',
        background: 'white',
        fontWeight: 600,
        border: '2px dashed #009BE1'

    },
    marginBottom2: {
        marginBottom: theme.spacing(3)
    },
    marginBottom1: {
        marginBottom: theme.spacing(1)
    },
    bold: {
        fontWeight: '600'
    },
    muted: {
        color: theme.palette.text.disabled
    },
    toggle: {
        "& .MuiToggleButton-root": {
            border: 0,
            color: theme.palette.text.primary,
            background: theme.palette.background.default,
            minWidth: theme.spacing(10),
            textTransform: 'capitalize',
            padding: '6px'
        },
        "& .Mui-selected": {
            backgroundColor: theme.palette.primary.main + ' !important',
            color: 'white',
            fontWeight: 600
        }
    },
    bgTransparent: {
        background: 'transparent'
    },
    paddingLeft12: {
        paddingLeft: '12px'
    },
    link: {
        textDecoration: 'none'
    },
    day: {
        fontSize: theme.spacing(1.75),

        paddingLeft: theme.spacing(2)
    },
    regularWt: {
        fontWeight: 500,
    },
    green: {
        color: theme.palette.success.main
    },
    availability: {
        paddingLeft: theme.spacing(2),
        fontSize: theme.spacing(1.75)
    },
    subText: {
        fontSize: theme.spacing(1.75)
    },

    red: {
        color: 'indianred'
    },
    paddingRight12: {
        paddingRight: theme.spacing(1.5)
    },
    error: {
        "&.MuiFormHelperText-root.Mui-error": {
            color: 'indianRed !important',
        },
    },
    errorText: {
        fontSize: theme.spacing(1.5),
        paddingTop: theme.spacing(1),
        color: 'indianRed'
    },
    promo: {

        textTransform: 'uppercase !important'

    },

    promoTextField: {
        padding: 0
    },
    removeGolferButton: {
        marginLeft: theme.spacing(2),
        textTransform: 'Capitalize',
        color: theme.palette.error.main,
        border: '1px solid rgba(205, 92, 92, 0.5)'

    }, marginTop10Mob: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            marginTop: theme.spacing(5)
        }
    },
    padding2Mob: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
        }
    }


});


class AddPlayers extends Component {

    state = {
        isValid: true,
        loading: true,
        errors: []
    }

    addAnotherPlayer = () => {

        this.addNewPlayerHelper();

    }

    removeGolfer = (index) => {
        console.log(index)
        this.props.removeSelectedTicketsInfo(index);
    }

    changePlayerName = debounce((name, index) => {


        if (!this.preValidateGolferName(name, index)) {
            // Should update the value in the redux  - No member val check is done though to correct price
            let playerObj = {};
            playerObj = {
                ...this.props.selectedTicketsInfo[index].player,
                name: name,
            }

            let updatedPlayerInfo = {
                ...this.props.selectedTicketsInfo[index],
                player: playerObj
            }

            this.props.updateSelectedTicketsInfo(updatedPlayerInfo, index)
            return;
        }

        if (this.props.selectedTicketsInfo[index].player.validMember && this.props.selectedTicketsInfo[index].player.memberEmail) {
            let playerObj = {};
            playerObj = {
                ...this.props.selectedTicketsInfo[index].player,
                name: name,
            }

            let updatedPlayerInfo = {
                ...this.props.selectedTicketsInfo[index],
                player: playerObj
            }

            this.props.updateSelectedTicketsInfo(updatedPlayerInfo, index)


        } else {
            this.props.checkIfValidMemberByName(name, (memberBenefits) => {

                let playerObj = {};
                playerObj = {
                    ...this.props.selectedTicketsInfo[index].player,
                    name: name,
                    memberType: memberBenefits.validMember ? 'Member' : 'Guest',
                    cannotMatchName: memberBenefits.validMember ? false : true,
                    validMember: memberBenefits.validMember,
                    memberEmail: memberBenefits.validMember ? undefined : this.props.selectedTicketsInfo[index].player.memberEmail
                }
                if (memberBenefits.memberInfo) {
                    playerObj.memberInfo = memberBenefits.memberInfo;
                } else {
                    playerObj.memberInfo = undefined;
                }

                let processingFeeForTicketAmt = this.computeProcessingFee(this.props.postingMeta.ticketAmount);
                let discountedPrice = (this.props.postingMeta.ticketAmount * memberBenefits.percentValueForMember) / 100;
                let postingOneTicketPriceWithOutFees = this.props.postingMeta.ticketAmount;
                let postingOneTicketPriceWithFees = this.props.postingMeta.ticketAmount + processingFeeForTicketAmt;


                if (memberBenefits.percentValueForMember >= 0) {
                    postingOneTicketPriceWithOutFees = this.props.postingMeta.ticketAmount - discountedPrice;

                    processingFeeForTicketAmt = this.computeProcessingFee(postingOneTicketPriceWithOutFees);
                    postingOneTicketPriceWithFees = processingFeeForTicketAmt + postingOneTicketPriceWithOutFees;
                }

                //For accompnaying guests

                if (memberBenefits.percentValue >= 0) {
                    this.updatePriceForGuestsWhoAccompany(memberBenefits.percentValue, index)
                }

                // This is unnecessarily apply 20% discount if a member enters a name , gets validated as a valid member with accompanied guest disc, now he removes his name,
                //i.e make non member - then the 20% gets still applied to him from previous 

                let discountPercPreviouslyActive = this.checkIfDiscountForAccompaniedGuestInPlay(memberBenefits.percentValue, index);
                if (discountPercPreviouslyActive > 0) {
                    let discountedPrice = (this.props.postingMeta.ticketAmount * discountPercPreviouslyActive) / 100;
                    if (this.props.postingMeta.ticketAmount - discountedPrice < postingOneTicketPriceWithOutFees) {

                        postingOneTicketPriceWithOutFees = this.props.postingMeta.ticketAmount - discountedPrice;
                        processingFeeForTicketAmt = this.computeProcessingFee(postingOneTicketPriceWithOutFees);
                        postingOneTicketPriceWithFees = processingFeeForTicketAmt + postingOneTicketPriceWithOutFees;
                    }

                }

                let updatedPlayerInfo = {
                    ...this.props.selectedTicketsInfo[index],
                    player: playerObj,
                    postingOneTicketProcessingFee: processingFeeForTicketAmt,
                    postingOneTicketPriceWithOutFees: postingOneTicketPriceWithOutFees,
                    postingOneTicketPriceWithFees: postingOneTicketPriceWithFees,
                    postingTotalPurchaseAmt: postingOneTicketPriceWithFees,
                    memberDiscountApplied: memberBenefits.percentValueForMember > 0 ? memberBenefits.percentValueForMember : undefined,
                    accompaniedGuestDiscountApplied: memberBenefits.percentValue > 0 ? memberBenefits.percentValue : undefined,
                }

                if (this.props.selectedTicketsInfo[index].payLater) {
                    if (this.validateEmail(this.props.selectedTicketsInfo[index].payLaterEmail)) {
                        updatedPlayerInfo = {
                            ...this.props.selectedTicketsInfo[index],
                            player: playerObj,
                            payLaterMeta: {
                                postingOneTicketProcessingFee: processingFeeForTicketAmt,
                                postingOneTicketPriceWithOutFees: postingOneTicketPriceWithOutFees,
                                postingOneTicketPriceWithFees: postingOneTicketPriceWithFees,
                                postingTotalPurchaseAmt: postingOneTicketPriceWithFees
                            },
                            postingOneTicketProcessingFee: 0,
                            postingOneTicketPriceWithOutFees: 0,
                            postingOneTicketPriceWithFees: 0,
                            postingTotalPurchaseAmt: 0,
                            memberDiscountApplied: memberBenefits.percentValueForMember > 0 ? memberBenefits.percentValueForMember : undefined,
                            accompaniedGuestDiscountApplied: memberBenefits.percentValue > 0 ? memberBenefits.percentValue : undefined,
                        }
                    }

                }
                this.props.updateSelectedTicketsInfo(updatedPlayerInfo, index);
                this.validateGolfers()


            })

        }


    }, 200)

    calculateTotalCosts = () => {
        // would be in state mostly
        let cartInfo = {};
        let noFeeAmount = 0;
        let totalTicketQty = 0;
        let totalProcessingFee = 0;
        let totalTaxAmount = 0;
        let totalChargeAmount = 0;

        this.props.selectedTicketsInfo.forEach((player, index) => {
            totalTicketQty++;
            totalChargeAmount = totalChargeAmount + player.postingOneTicketPriceWithFees;
            noFeeAmount = noFeeAmount + player.postingOneTicketPriceWithOutFees;
            totalProcessingFee = totalProcessingFee + player.postingOneTicketProcessingFee;
        })

        let discountAmtPerTicket = 0;
        let discountPerc = 0;

        if (this.props.coupon) {

            if (this.props.coupon.amount_off != null) {

                //Only tickets purchased with a price associated is considered for discount
                discountAmtPerTicket = (this.props.coupon.amount_off / 100);


            }
            if (this.props.coupon.percent_off != null) {

                discountPerc = this.props.coupon.percent_off;

            }

        }

        if (discountAmtPerTicket > 0) {

            totalChargeAmount = totalChargeAmount - discountAmtPerTicket;
            noFeeAmount = noFeeAmount - discountAmtPerTicket;
        }

        if (discountPerc > 0) {
            totalChargeAmount = parseFloat((totalChargeAmount - (totalChargeAmount * (discountPerc / 100))).toFixed(2));
            noFeeAmount = parseFloat((noFeeAmount - (noFeeAmount * (discountPerc / 100))).toFixed(2));
        }

        if (noFeeAmount <= 0) {

            totalProcessingFee = 0;
            totalChargeAmount = 0;

        }

        //Selected ticket posting and quantity details

        cartInfo.totalChargeAmount = parseFloat((totalChargeAmount).toFixed(2));


        if (this.props.includeTax && this.props.taxPercent > 0 && !this.props.taxInclusiveOnFee) {
            //Calculating tax on already rounded off noFeeAmount i.e calculating tax on rounded off to 2 digits noFeeAmount
            noFeeAmount = parseFloat((noFeeAmount).toFixed(2));
            totalTaxAmount = parseFloat((noFeeAmount * ((this.props.taxPercent) / 100)).toFixed(2));

            cartInfo.totalChargeAmount = cartInfo.totalChargeAmount +
                parseFloat((totalTaxAmount).toFixed(2));

            cartInfo.totalChargeAmount = parseFloat((cartInfo.totalChargeAmount).toFixed(2))

            totalChargeAmount = cartInfo.totalChargeAmount;


        } else if (this.props.includeTax && this.props.taxPercent > 0 && this.props.taxInclusiveOnFee) {
            noFeeAmount = parseFloat((noFeeAmount).toFixed(2));
            totalTaxAmount = parseFloat((totalChargeAmount * ((this.props.taxPercent) / 100)).toFixed(2));
            cartInfo.totalChargeAmount = cartInfo.totalChargeAmount +
                parseFloat((totalTaxAmount).toFixed(2));

            cartInfo.totalChargeAmount = parseFloat((cartInfo.totalChargeAmount).toFixed(2))

            totalChargeAmount = cartInfo.totalChargeAmount;


        }


        cartInfo.totalCartProcessingFees = totalProcessingFee;
        cartInfo.totalTicketsRequested = totalTicketQty;
        cartInfo.selectedTicketsInfo = [];
        cartInfo.selectedTicketsInfo = this.props.selectedTicketsInfo;

        this.props.setCartInfo(totalProcessingFee, totalChargeAmount, totalTicketQty, parseFloat((totalTaxAmount).toFixed(2)), () => {
            console.log("Cart totals updated")
            console.log(totalProcessingFee, totalChargeAmount, totalTicketQty)
        })

        // COULD BE IN STATE
        if (this.props.coupon) {
            cartInfo.coupon = this.props.coupon;
        }




        console.log(cartInfo);
    }

    changePlayerMembership = debounce((email, index) => {
        if (email && email != '') {
            email = email.toLowerCase()
        }
        if (!this.preValidateGolferEmail(email, index)) {
            let playerObj = {};
            playerObj = {
                ...this.props.selectedTicketsInfo[index].player,
                memberEmail: email,
            }

            let updatedPlayerInfo = {
                ...this.props.selectedTicketsInfo[index],
                player: playerObj
            }
            this.props.updateSelectedTicketsInfo(updatedPlayerInfo, index)

            return;
        }

        /*if (email.length > 4) {
            // If first 4 is number
            if (email.substring(0, 4).match(/^[0-9]+$/) != null) {
                //If no hyphen
                if (email.split('-')[1] == undefined) {
                    //add hyphen yourself
                    email = email.substring(0, 4) + '-' + email.substring(4, email.length)
                } else {
                    if (email.length == 5 && email.split('-')[1] == '') {
                        email = email + '1'
                    }
                }

            }
        }
        if (email.length == 4 && email.split('-')[1] === undefined) {
            if (email.substring(0, 4).match(/^[0-9]+$/) != null) {
                email = email + '-1'
            }

        } */

        this.props.checkIfValidMemberByEmail(email, (memberBenefits) => {

            let playerObj = {};
            playerObj = {
                ...this.props.selectedTicketsInfo[index].player,
                memberType: memberBenefits.validMember ? 'Member' : 'Guest',
                cannotMatchName: memberBenefits.validMember ? false : true,
                validMember: memberBenefits.validMember,
                memberEmail: email
            }

            let processingFeeForTicketAmt = this.computeProcessingFee(this.props.postingMeta.ticketAmount);
            let discountedPrice = (this.props.postingMeta.ticketAmount * memberBenefits.percentValueForMember) / 100;
            let postingOneTicketPriceWithOutFees = this.props.postingMeta.ticketAmount;
            let postingOneTicketPriceWithFees = this.props.postingMeta.ticketAmount + processingFeeForTicketAmt;

            if (memberBenefits.percentValueForMember >= 0) {
                postingOneTicketPriceWithOutFees = this.props.postingMeta.ticketAmount - discountedPrice;

                processingFeeForTicketAmt = this.computeProcessingFee(postingOneTicketPriceWithOutFees);
                postingOneTicketPriceWithFees = processingFeeForTicketAmt + postingOneTicketPriceWithOutFees;
            }
            //For accompnaying guests
            if (memberBenefits.percentValue >= 0) {
                this.updatePriceForGuestsWhoAccompany(memberBenefits.percentValue, index)
            }

            let discountPercPreviouslyActive = this.checkIfDiscountForAccompaniedGuestInPlay(memberBenefits.percentValue, index);
            if (discountPercPreviouslyActive > 0) {
                let discountedPrice = (this.props.postingMeta.ticketAmount * discountPercPreviouslyActive) / 100;
                if (this.props.postingMeta.ticketAmount - discountedPrice < postingOneTicketPriceWithOutFees) {

                    postingOneTicketPriceWithOutFees = this.props.postingMeta.ticketAmount - discountedPrice;
                    processingFeeForTicketAmt = this.computeProcessingFee(postingOneTicketPriceWithOutFees);
                    postingOneTicketPriceWithFees = processingFeeForTicketAmt + postingOneTicketPriceWithOutFees;
                }

            }


            let updatedPlayerInfo = {
                ...this.props.selectedTicketsInfo[index],
                player: playerObj,
                postingOneTicketProcessingFee: processingFeeForTicketAmt,
                postingOneTicketPriceWithOutFees: postingOneTicketPriceWithOutFees,
                postingOneTicketPriceWithFees: postingOneTicketPriceWithFees,
                postingTotalPurchaseAmt: postingOneTicketPriceWithFees,

                memberDiscountApplied: memberBenefits.percentValueForMember > 0 ? memberBenefits.percentValueForMember : undefined,
                accompaniedGuestDiscountApplied: memberBenefits.percentValue > 0 ? memberBenefits.percentValue : undefined,
            }



            // Case where paylater option is chosen & a valid email in file 
            if (this.props.selectedTicketsInfo[index].payLater) {
                if (this.validateEmail(this.props.selectedTicketsInfo[index].payLaterEmail)) {
                    updatedPlayerInfo = {
                        ...this.props.selectedTicketsInfo[index],
                        player: playerObj,
                        payLaterMeta: {
                            postingOneTicketProcessingFee: processingFeeForTicketAmt,
                            postingOneTicketPriceWithOutFees: postingOneTicketPriceWithOutFees,
                            postingOneTicketPriceWithFees: postingOneTicketPriceWithFees,
                            postingTotalPurchaseAmt: postingOneTicketPriceWithFees
                        },
                        postingOneTicketProcessingFee: 0,
                        postingOneTicketPriceWithOutFees: 0,
                        postingOneTicketPriceWithFees: 0,
                        postingTotalPurchaseAmt: 0,

                        memberDiscountApplied: memberBenefits.percentValueForMember > 0 ? memberBenefits.percentValueForMember : undefined,
                        accompaniedGuestDiscountApplied: memberBenefits.percentValue > 0 ? memberBenefits.percentValue : undefined,
                    }
                }

            }

            this.props.updateSelectedTicketsInfo(updatedPlayerInfo, index)
            this.validateGolfers()
            // this.calculateTotalCosts();
        })

    }, 200);

    updatePriceForGuestsWhoAccompany = (percentageForAccompanying, indexToSkip) => {

        let percForAccompanying = this.checkIfDiscountForAccompaniedGuestInPlay(percentageForAccompanying, indexToSkip)

        this.props.selectedTicketsInfo.forEach((playerInfo, index) => {

            if (index != indexToSkip && !playerInfo.player.validMember) {
                let processingFeeForTicketAmt = this.computeProcessingFee(this.props.postingMeta.ticketAmount);
                let discountedPrice = (this.props.postingMeta.ticketAmount * percForAccompanying) / 100;
                let postingOneTicketPriceWithOutFees = this.props.postingMeta.ticketAmount;
                let postingOneTicketPriceWithFees = this.props.postingMeta.ticketAmount + processingFeeForTicketAmt;

                if (percForAccompanying >= 0) {
                    postingOneTicketPriceWithOutFees = this.props.postingMeta.ticketAmount - discountedPrice;
                    processingFeeForTicketAmt = this.computeProcessingFee(postingOneTicketPriceWithOutFees);

                    postingOneTicketPriceWithFees = processingFeeForTicketAmt + postingOneTicketPriceWithOutFees;
                }
                // TO-DO If the price difference mattered then apply ! else do not , it made 0$ to 32$ 
                let updatedPlayerInfo = {
                    ...this.props.selectedTicketsInfo[index],
                    postingOneTicketProcessingFee: processingFeeForTicketAmt,
                    postingOneTicketPriceWithOutFees: postingOneTicketPriceWithOutFees,
                    postingOneTicketPriceWithFees: postingOneTicketPriceWithFees,
                    postingTotalPurchaseAmt: postingOneTicketPriceWithFees
                }
                // If paylater option on and is valid while applying discount - consider the SEND INVOICE CASE - update paylatermeta
                if (this.props.selectedTicketsInfo[index].payLater) {
                    if (this.validateEmail(this.props.selectedTicketsInfo[index].payLaterEmail)) {
                        updatedPlayerInfo = {
                            ...this.props.selectedTicketsInfo[index],
                            payLaterMeta: {
                                postingOneTicketPriceWithOutFees: postingOneTicketPriceWithOutFees,
                                postingOneTicketPriceWithFees: postingOneTicketPriceWithFees,
                                postingTotalPurchaseAmt: postingOneTicketPriceWithFees
                            },
                            postingOneTicketProcessingFee: 0,
                            postingOneTicketPriceWithOutFees: 0,
                            postingOneTicketPriceWithFees: 0,
                            postingTotalPurchaseAmt: 0
                        }
                    }

                }

                this.props.updateSelectedTicketsInfo(updatedPlayerInfo, index)
            }

        })


    }

    computePriceForGivenAmt = (ticketAmt) => {
        //   ticketAmt + this.computeProcessingFee(ticketAmt) + 
        ticketAmt = parseFloat((ticketAmt).toFixed(2));
        let ticketAmtWithProcFee = ticketAmt + this.computeProcessingFee(ticketAmt);
        let totalTaxAmount = 0;

        if (this.props.includeTax && this.props.taxPercent > 0 && !this.props.taxInclusiveOnFee) {
            //Calculating tax on already rounded off noFeeAmount i.e calculating tax on rounded off to 2 digits noFeeAmount
            ticketAmt = parseFloat((ticketAmt).toFixed(2));
            totalTaxAmount = (ticketAmt * ((this.props.taxPercent) / 100));

            return ticketAmtWithProcFee +
                parseFloat((totalTaxAmount).toFixed(2));


        } else if (this.props.includeTax && this.props.taxPercent > 0 && this.props.taxInclusiveOnFee) {

            totalTaxAmount = (ticketAmtWithProcFee * ((this.props.taxPercent) / 100));
            return ticketAmtWithProcFee +
                parseFloat((totalTaxAmount).toFixed(2));


        } else {
            return ticketAmtWithProcFee;
        }


    }
    computeProcessingFee = (ticketAmt) => {
        let processingFee = 0;
        if (ticketAmt === 0) {
            processingFee = 0;
        } else {
            processingFee = 2;
        }
        if (ticketAmt > 0 && ticketAmt < 38) {
            processingFee = 2;
        }
        if (ticketAmt >= 38 && ticketAmt < 58) {
            processingFee = 3;
        }
        if (ticketAmt >= 58 && ticketAmt < 78) {
            processingFee = 4;
        }
        if (ticketAmt >= 78 && ticketAmt < 98) {
            processingFee = 5;
        }
        if (ticketAmt >= 98) {
            processingFee = 6 + ((ticketAmt - 98) * 4);
        }
        return processingFee;
    }

    addNewPlayerHelper = () => {

        if (this.props.postingMeta.ticketsRemaining <= this.props.selectedTicketsInfo.length) {
            this.props.enqueueSnackbar('No more slots available for ' + this.props.postingMeta.title.replace('Tee Time', ''), { variant: 'error' });

            return;
        }

        let discountPercToApply = 0;
        this.props.selectedTicketsInfo.forEach((playerInfo, index) => {
            if (playerInfo.accompaniedGuestDiscountApplied) {
                discountPercToApply = Math.max(playerInfo.accompaniedGuestDiscountApplied, discountPercToApply)
            }
        })

        let processingFeeForTicketAmt = this.computeProcessingFee(this.props.postingMeta.ticketAmount);

        let discountedPrice = (this.props.postingMeta.ticketAmount * discountPercToApply) / 100;
        let postingOneTicketPriceWithOutFees = this.props.postingMeta.ticketAmount;
        let postingOneTicketPriceWithFees = this.props.postingMeta.ticketAmount + processingFeeForTicketAmt;

        if (discountPercToApply >= 0) {
            postingOneTicketPriceWithOutFees = this.props.postingMeta.ticketAmount - discountedPrice;
            processingFeeForTicketAmt = this.computeProcessingFee(postingOneTicketPriceWithOutFees);
            postingOneTicketPriceWithFees = processingFeeForTicketAmt + postingOneTicketPriceWithOutFees;
        }

        this.props.addNewPlayer({
            noOfTicketsSelected: 1,
            player: {
                memberType: 'Guest'
            },
            posting: this.props.match.params.postingId,
            postingOneTicketProcessingFee: processingFeeForTicketAmt,
            postingOneTicketPriceWithOutFees: postingOneTicketPriceWithOutFees,
            postingOneTicketPriceWithFees: postingOneTicketPriceWithFees,
            postingTotalPurchaseAmt: postingOneTicketPriceWithFees
        }, () => {

            console.log(this.props.selectedTicketsInfo);

        })


    }

    addNewMemberPlayerHelper = () => {

        if (this.props.postingMeta.ticketsRemaining <= this.props.selectedTicketsInfo.length) {
            return;
        }
        let index = 0;
        this.props.checkIfValidMemberByEmail(this.props.currentUser.email, (memberBenefits) => {

            let playerObj = {};
            playerObj = {
                memberType: memberBenefits.validMember ? 'Member' : 'Guest',
                cannotMatchName: true,
                validMember: memberBenefits.validMember,
                name: this.props.currentUser.name,
                memberEmail: this.props.currentUser.email
            }

            let processingFeeForTicketAmt = this.computeProcessingFee(this.props.postingMeta.ticketAmount);

            let discountedPrice = (this.props.postingMeta.ticketAmount * memberBenefits.percentValueForMember) / 100;
            let postingOneTicketPriceWithOutFees = this.props.postingMeta.ticketAmount;
            let postingOneTicketPriceWithFees = this.props.postingMeta.ticketAmount + processingFeeForTicketAmt;

            if (memberBenefits.percentValueForMember > 0) {
                postingOneTicketPriceWithOutFees = this.props.postingMeta.ticketAmount - discountedPrice;

                processingFeeForTicketAmt = this.computeProcessingFee(postingOneTicketPriceWithOutFees);
                postingOneTicketPriceWithFees = processingFeeForTicketAmt + postingOneTicketPriceWithOutFees;
            }


            let updatedPlayerInfo = {

                noOfTicketsSelected: 1,
                player: playerObj,
                posting: this.props.match.params.postingId,

                postingOneTicketProcessingFee: processingFeeForTicketAmt,
                postingOneTicketPriceWithOutFees: postingOneTicketPriceWithOutFees,
                postingOneTicketPriceWithFees: postingOneTicketPriceWithFees,
                postingTotalPurchaseAmt: postingOneTicketPriceWithFees,
                memberDiscountApplied: memberBenefits.percentValueForMember > 0 ? memberBenefits.percentValueForMember : undefined,
                accompaniedGuestDiscountApplied: memberBenefits.percentValue > 0 ? memberBenefits.percentValue : undefined,
            }

            this.props.updateSelectedTicketsInfo(updatedPlayerInfo, index)

        });

    }




    componentDidMount() {
        window.scrollTo(0, 0);
        console.log(this.props);
        if (this.props.includeTax === undefined) {
            this.props.getTeeTimePostingsMeta(this.props.match.params.eventId, false, () => {

            });
        }

        this.props.getLoggedInUserCustInfoFromSellerStripe((data) => {
            console.log(data);
        })

        this.props.getPostingInfo(this.props.match.params.postingId, (postingMeta) => {

            this.setState({
                loading: true
            })
            this.props.getEventData(postingMeta.event);
            if (this.props.selectedTicketsInfo.length === 0) {
                if (this.props.currentUser) {
                    this.addNewMemberPlayerHelper()
                } else {
                    this.addNewPlayerHelper()
                }

            }

            // when component is loading if already has players entered, it should consider revising price ! 

            this.setState({
                loading: false
            })

        });

    }

    componentDidUpdate() {
        this.calculateTotalCosts();

    }

    proceedToPayment = () => {
        let isValid = this.validateGolfers();
        if (isValid) {
            console.log("Valid");
            this.props.history.push('/pay');
        } else {
            let errMsg = '';
            if (this.props.selectedTicketsInfo.length > 1) {
                errMsg = 'Please enter Golfer names to proceed'
            } else {
                errMsg = 'Please enter Golfer 1 name to proceed'
            }
            this.props.enqueueSnackbar(errMsg, { variant: 'error' });

        }
    }

    preValidateGolferName = (name, index) => {
        let valid = true;
        let errors = [];

        let playerNameHashMap = {};
        this.props.selectedTicketsInfo.forEach((playerInfo, index) => {
            if (playerInfo.player && playerInfo.player.name && playerInfo.player.name != '') {
                playerNameHashMap[playerInfo.player.name.toLowerCase()] = true;
            }
        })

        if (playerNameHashMap[name.toLowerCase()]) {
            let errObj = {};
            valid = false;
            errObj.error = true;
            errObj.errorMsg = "Please do not use the same golfer information"
            errors[index] = errObj;
            this.setState({
                isValid: valid,
                errors: errors
            })
        } else {
            let errObj = {};
            errObj.error = false;
            errors[index] = errObj;
            this.setState({
                ...this.state,
                errors: [
                    ...this.state.errors.slice(0, index),
                    errObj,
                    ...this.state.errors.slice(index + 1)
                ]
            })
        }

        return valid;

    }

    preValidateGolferEmail = (memberEmail, index) => {
        let valid = true;
        let errors = [];

        let playerNameHashMap = {};
        this.props.selectedTicketsInfo.forEach((playerInfo, index) => {
            if (playerInfo.player && playerInfo.player.memberEmail && playerInfo.player.memberEmail != '') {
                playerNameHashMap[playerInfo.player.memberEmail.toLowerCase()] = true;
            }
        })

        if (playerNameHashMap[memberEmail.toLowerCase()]) {
            let errObj = {};
            valid = false;
            errObj.error = true;
            errObj.errorMsgEmail = "Please do not use the same golfer information"
            errors[index] = errObj;
            this.setState({
                isValid: valid,
                errors: errors
            })
        } else {
            let errObj = {};
            errObj.error = false;
            errors[index] = errObj;
            this.setState({
                ...this.state,
                errors: [
                    ...this.state.errors.slice(0, index),
                    errObj,
                    ...this.state.errors.slice(index + 1)
                ]
            })
        }

        return valid;

    }




    validateGolfers = () => {
        let valid = true;
        let errors = [];

        let playerNameHashMap = {};
        let playerMemberEmailHashMap = {};
        this.props.selectedTicketsInfo.forEach((playerInfo, index) => {
            let errObj = {};
            index++;

            if (playerInfo.player.name === "" || playerInfo.player.name == undefined) {
                valid = false;
                errObj.error = true;
                // Do not change the errMsg tht
                errObj.errorMsg = "Please enter a Golfer " + index + " name to proceed";


            } else {
                if (playerNameHashMap[playerInfo.player.name]) {
                    // error
                    valid = false;
                    errObj.error = true;
                    errObj.errorMsg = "Please do not use the same golfer information"
                } else {
                    playerNameHashMap[playerInfo.player.name] = true;
                    errObj.error = false;
                }

                if (playerInfo.player && playerInfo.player.memberEmail && playerInfo.player.memberEmail != '') {
                    if (playerMemberEmailHashMap[playerInfo.player.memberEmail]) {
                        // error
                        valid = false;
                        errObj.error = true;
                        errObj.errorMsgEmail = "Please do not use the same golfer information"
                    } else {
                        playerMemberEmailHashMap[playerInfo.player.memberEmail] = true;
                        errObj.error = false;
                    }
                }



            }
            errors.push(errObj);
        })
        this.setState({
            isValid: valid,
            errors: errors
        })
        return valid;
    }

    checkIfDiscountForAccompaniedGuestInPlay = (currentMemberAccompaniedGuestDiscount, indexOfCurrentMember) => {
        let discountPercToApply = 0;
        this.props.selectedTicketsInfo.forEach((playerInfo, index) => {
            if (index === indexOfCurrentMember) {
                discountPercToApply = Math.max(currentMemberAccompaniedGuestDiscount, discountPercToApply);
            } else {
                // If any accompaniedGuestDiscount
                if (playerInfo.accompaniedGuestDiscountApplied) {
                    discountPercToApply = Math.max(playerInfo.accompaniedGuestDiscountApplied, discountPercToApply)
                }
            }

        })

        return discountPercToApply
    }

    onChangePayNow = (payNow, index) => {
        if (payNow) {
            // revert
            this.resetPriceAsPayNowChosenOrInvalidEmail(index);
        }
    }

    movePriceObjsToPayLaterMeta = (email, index) => {
        let updatedPlayerInfo = {};
        if (!this.props.selectedTicketsInfo[index].payLaterMeta) {
            updatedPlayerInfo = {
                ...this.props.selectedTicketsInfo[index],
                payLater: true,
                payLaterEmail: email,
                payLaterMeta: {
                    postingOneTicketPriceWithOutFees: this.props.selectedTicketsInfo[index].postingOneTicketPriceWithOutFees,
                    postingOneTicketPriceWithFees: this.props.selectedTicketsInfo[index].postingOneTicketPriceWithFees,
                    postingTotalPurchaseAmt: this.props.selectedTicketsInfo[index].postingOneTicketPriceWithFees
                },
                postingOneTicketPriceWithOutFees: 0,
                postingOneTicketPriceWithFees: 0,
                postingTotalPurchaseAmt: 0
            }
            this.props.updateSelectedTicketsInfo(updatedPlayerInfo, index)
        } else {
            // Still valid but no need to worry about payLaterMeta so just update the email 
            updatedPlayerInfo = {
                ...this.props.selectedTicketsInfo[index],
                payLaterEmail: email
            }
            this.props.updateSelectedTicketsInfo(updatedPlayerInfo, index)
        }

    }

    resetPriceAsPayNowChosenOrInvalidEmail = (index) => {
        let updatedPlayerInfo = {};
        if (this.props.selectedTicketsInfo[index].payLaterMeta) {
            updatedPlayerInfo = {
                ...this.props.selectedTicketsInfo[index],
                postingOneTicketPriceWithOutFees: this.props.selectedTicketsInfo[index].payLaterMeta.postingOneTicketPriceWithOutFees,
                postingOneTicketPriceWithFees: this.props.selectedTicketsInfo[index].payLaterMeta.postingOneTicketPriceWithFees,
                postingTotalPurchaseAmt: this.props.selectedTicketsInfo[index].payLaterMeta.postingOneTicketPriceWithFees,
                payLater: false,
                payLaterMeta: undefined,
                payLaterEmail: undefined
            }
        } else {
            updatedPlayerInfo = {
                ...this.props.selectedTicketsInfo[index],
                payLater: false,
                payLaterMeta: undefined,
                payLaterEmail: undefined
            }
        }

        this.props.updateSelectedTicketsInfo(updatedPlayerInfo, index)
    }

    validateEmail = (email) => {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    }
    onChangeEmailForInvoice = (email, index) => {
        console.log(email, index);

        if (this.validateEmail(email)) {
            console.log('VALID');
            this.movePriceObjsToPayLaterMeta(email, index);

        } else {
            console.log('INVALID');
            // Should reset the price as before ! 
            // If already once a right email is entered and now a bad email - revert back
            this.resetPriceAsPayNowChosenOrInvalidEmail(index);

        }

    }

    couponEntered = debounce((couponEntered) => {
        console.log(couponEntered)
        this.props.getCouponInfo(couponEntered, (err, data) => {
            if (err) {
                // Invalid coupon msg

            }
        })


    }, 500)

    render() {

        console.log(this.props.isLoginTokenValid);
        console.log(this.props.currentUser);

        const { classes, totalChargeAmount, totalCartProcessingFees, totalCartTax, selectedTicketsInfo, postingMeta, eventMeta } = this.props;
        console.log("Selected Tickets Info");
        console.log(selectedTicketsInfo);

        let payLaterInPlay = false;
        selectedTicketsInfo.forEach((playerInfo, index) => {
            if (playerInfo.payLater) {
                payLaterInPlay = true;
            }
        })


        return <div className={classes.root}>

            <Grid container justify="space-around" className={classes.marginTop10Mob}>
                <Grid item lg={10} md={8} md xs >
                    <div className={classnames(classes.header, classes.marginBottom3)}>Book Tee Times</div>
                </Grid>
            </Grid>
            <Grid container wrap='wrap' justify="center" spacing={1}>
                <Grid item lg={6} md={8} xs={12}>
                    <Paper className={classes.padding2}>
                        <Grid container spacing={3} >
                            <Grid item xs={2} ><Paper className={classes.muted}>Date</Paper></Grid>
                            <Grid item xs><Paper className={classes.bold}>{eventMeta.startDate && moment(eventMeta.startDate).format('MMM D')}
                                {eventMeta.startDate && moment(eventMeta.startDate).isSame(moment(), 'day') && <span style={{ paddingLeft: '6px' }}> (Today)</span>}
                                <span className={classnames(classes.day, classes.muted, classes.regularWt)}> {moment(eventMeta.startDate).format('dddd')}</span></Paper></Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={2}><Paper className={classes.muted}>Tee Time</Paper></Grid>
                            <Grid item xs><Paper className={classes.bold}>{postingMeta.title} <span className={classnames(classes.availability, classes.green, classes.regularWt)}> {postingMeta.ticketsRemaining} Available</span></Paper> </Grid>
                        </Grid>
                    </Paper>


                    {this.props.selectedTicketsInfo.map((playerInfo, index) => {
                        return <form key={index} id={'form_' + index} noValidate autoComplete="off"><div key={index} className={classes.playerContainer}>
                            <Grid container item justify="space-between" className={classes.padding2}>
                                <Grid item>
                                    Golfer {index + 1}
                                    {index > 0 && <Button onClick={() => { this.removeGolfer(index) }} size="small" className={classes.removeGolferButton} variant="outlined" >
                                        Remove Golfer
                                    </Button>}
                                </Grid>
                                <Grid item>
                                    ${playerInfo.postingOneTicketPriceWithOutFees}
                                </Grid>
                            </Grid>

                            <Paper className={classnames(classes.marginTop1, classes.padding2MdUp)}>

                                <Grid container spacing={3} alignItems="center">
                                    <Hidden mdDown><Grid item xs={2} ><Paper className={classes.muted}>Name</Paper></Grid></Hidden>
                                    <Grid item xs>
                                        <Paper>
                                            <TextField error={this.state.errors.length > 0 && this.state.errors[index] && this.state.errors[index].error} helperText={this.state.errors.length > 0 && this.state.errors[index] && this.state.errors[index].error ? this.state.errors[index].errorMsg : ''} FormHelperTextProps={{ classes: { error: classes.error } }} InputProps={{ disableUnderline: true, spellCheck: false }} id="filled-name" defaultValue={playerInfo.player.name} variant="filled" onChange={(e) => { this.changePlayerName(e.target.value, index, e.target.refs) }} placeholder={`Enter Golfer ${index + 1} Name`} />
                                        </Paper>

                                        {/*playerInfo && playerInfo.player && playerInfo.player.validMember && !playerInfo.player.memberEmail &&
                                            < Grid container item justify="flex-end">
                                                <Paper className={classnames(classes.paddingRight12, classes.green, classes.subText)}>Valid Member</Paper>
                    </Grid>*/}
                                    </Grid>
                                </Grid>

                                {
                                   <Grid container spacing={3} alignItems="center">
                                        <Hidden mdDown><Grid item xs={2} ><Paper className={classes.muted}>Email</Paper></Grid></Hidden>
                                        <Grid item xs>
                                            <Paper>
                                                <TextField error={this.state.errors.length > 0 && this.state.errors[index] && this.state.errors[index].error} helperText={this.state.errors.length > 0 && this.state.errors[index] && this.state.errors[index].error ? this.state.errors[index].errorMsgEmail : ''} FormHelperTextProps={{ classes: { error: classes.error } }} InputProps={{ disableUnderline: true, spellCheck: false }} id="filled-email" defaultValue={playerInfo.player.memberEmail} onChange={(e) => { this.changePlayerMembership(e.target.value, index) }} variant="filled" placeholder={`Enter Golfer ${index + 1} Email`} />
                                            </Paper>

                                            {/*playerInfo && playerInfo.player && playerInfo.player.validMember &&
                                                <Grid container item justify="flex-end">
                                                    <Paper className={classnames(classes.paddingRight12, classes.green, classes.subText)}>Valid Member</Paper>
                                </Grid>*/}

                                        </Grid>



                                    </Grid>
                                }


                                {(playerInfo.postingOneTicketPriceWithOutFees > 0 || playerInfo.payLater) && index > 0 && <PayDecision onChangeEmailForInvoice={this.onChangeEmailForInvoice} index={index} payNowDefaultValue={playerInfo.payLater ? playerInfo.payLater : false} payLaterEmail={playerInfo.payLaterEmail} onChangePayNow={this.onChangePayNow}></PayDecision>}

                            </Paper>

                        </div></form>

                    })}


                    <div className={classes.marginTop3}>
                        <Grid container className={classes.padding2Mob}>
                            <Grid item xs>


                                <Button variant="contained" size="large" name="addAnotherPlayer" color="primary" fullWidth={true} className={classnames(classes.button, classes.buttonCustomDashed)} onClick={this.addAnotherPlayer}>
                                    + Add Another Player
                                </Button>

                            </Grid>
                        </Grid>
                    </div>

                </Grid>
                <Grid item lg={4} md xs={12}>
                    <Paper className={classes.padding2}>
                        <Grid container item direction="column" >
                            <Grid container item justify="center" className={classnames(classes.muted, classes.bold)}>Payment details</Grid>
                            <Grid item className={classnames(classes.marginBottom2, classes.marginTop3)}><Paper className={classes.muted}>Tee Time</Paper></Grid>

                            <Grid item ><Paper className={classnames(classes.bold, classes.marginBottom1)}>{postingMeta.title}</Paper></Grid>
                            <Grid item ><Paper>{moment(eventMeta.startDate).format('MMM D')}</Paper></Grid>

                            <Grid item ><Paper className={classnames(classes.marginTop3, classes.muted, classes.marginBottom2)}>Players</Paper></Grid>

                            {
                                this.props.selectedTicketsInfo.map((playerInfo, index) => {
                                    return <Grid key={index} container justify="space-between" className={classes.marginBottom2}>
                                        <Grid item >
                                            {playerInfo.player && playerInfo.player.name && <Grid item className={classes.paddingBottom1}><Paper>{playerInfo.player.name}</Paper></Grid>}

                                            {/*!playerInfo.player.validMember && <Grid item ><Paper><span className={classnames(classes.red, classes.subText)}>Non Member</span></Paper></Grid> */}

                                            {/*playerInfo.player.validMember && <Grid item ><Paper><span className={classnames(classes.green, classes.subText)}>Valid Member</span></Paper></Grid>*/}
                                        </Grid>

                                        {playerInfo.payLater ? <Grid item className={classes.subText}>send invoice (${this.computePriceForGivenAmt(playerInfo.payLaterMeta.postingOneTicketPriceWithOutFees)})</Grid> : <Grid item> ${playerInfo.postingOneTicketPriceWithOutFees}</Grid>}


                                    </Grid>
                                })
                            }

                            {
                                totalChargeAmount > 0 ?
                                    <Grid container >

                                        <Grid container justify="space-between" alignItems="center" className={classes.marginTop5}>
                                            <Grid item >
                                                <Grid item ><Paper><span className={classnames(classes.muted)}>Processing Fee</span></Paper></Grid>

                                            </Grid>
                                            <Grid item>
                                                <Grid item > ${totalCartProcessingFees}</Grid>
                                            </Grid>

                                        </Grid>
                                        {
                                            this.props.includeTax && this.props.taxPercent > 0 && <Grid container justify="space-between" alignItems="center" className={classes.marginTop2}>
                                                <Grid item >
                                                    <Grid item ><Paper><span className={classnames(classes.muted)}>Tax ({this.props.taxPercent} %)</span></Paper></Grid>

                                                </Grid>
                                                <Grid item>
                                                    <Grid item > ${totalCartTax}</Grid>
                                                </Grid>

                                            </Grid>
                                        }

                                        <Grid container justify="space-between" alignItems="center" className={classes.marginTop2}>
                                            <Grid item >
                                                <Grid item ><Paper><span className={classnames(classes.muted)}>{payLaterInPlay ? 'Due Now' : 'Total'} </span></Paper></Grid>

                                            </Grid>
                                            <Grid item>
                                                <Grid item style={{ fontWeight: 600 }}> ${totalChargeAmount}</Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    :

                                    !this.state.loading && <Grid container justify="space-between" alignItems="center" className={classes.marginTop5}>
                                        <Grid item >
                                            <Grid item ><Paper><span className={classnames(classes.muted)}>{payLaterInPlay ? 'Due Now' : 'Total'}</span></Paper></Grid>

                                        </Grid>
                                        <Grid item>
                                            <Grid item style={{ fontWeight: 600 }}> $0  </Grid>
                                        </Grid>

                                    </Grid>

                            }

                            <Grid item className={classes.marginTop8}><TextField fullWidth={true} noValidate autoComplete="off" defaultValue={this.props.coupon && this.props.coupon.id} className={classes.promoTextField} InputProps={{ disableUnderline: true, spellCheck: false, classes: { input: classes.promo } }} id="filled-basic" variant="filled" placeholder="PROMO CODE" onChange={(e) => this.couponEntered(e.target.value)} /></Grid>
                            {this.props.coupon && <Grid container item className={classnames(classes.green, classes.subtext)} justify="center" alignItems="center">coupon applied</Grid>}


                            <Grid item xs className={classes.marginTop5}>

                                <Button onClick={this.proceedToPayment} variant="contained" size="large" name="MakePaymentBtn" disabled={postingMeta.ticketsRemaining == 0} fullWidth={true} color="primary" className={classes.buttonPay} >
                                    {totalChargeAmount > 0 ? 'Proceed to checkout' : 'Continue to Reserve'}
                                </Button>

                            </Grid>
                            {!this.state.isValid && <Grid container item className={classes.errorText} justify="center" alignItems="center">Please enter the mandatory fields to proceed</Grid>}

                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div >
    }
}

const mapStateToProps = (state) => {
    return {
        totalCartTax: state.cart.totalCartTax,
        totalCartProcessingFees: state.cart.totalCartProcessingFees,
        totalChargeAmount: state.cart.totalChargeAmount,
        totalTicketsRequested: state.cart.totalTicketsRequested,
        eventMeta: state.cart.eventMeta,
        postingMeta: state.cart.postingMeta,
        selectedTicketsInfo: state.cart.selectedTicketsInfo,
        coupon: state.cart.coupon,
        taxPercent: state.meta.taxPercent,
        taxInclusiveOnFee: state.meta.taxInclusiveOnFee,
        includeTax: state.meta.includeTax
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPostingInfo: (postingId, cb) => dispatch(getTicketPostingInfo(postingId, cb)),
        getEventData: (eventId) => dispatch(getEventData(eventId)),
        addNewPlayer: (newPlayer, cb) => dispatch(addNewPlayer(newPlayer, cb)),
        updateSelectedTicketsInfo: (updatedPlayerInfo, index) => dispatch(updateSelectedTicketsInfo(updatedPlayerInfo, index)),
        removeSelectedTicketsInfo: (index) => dispatch(removeSelectedTicketsInfo(index)),
        checkIfValidMemberByName: (name, cb) => dispatch(checkIfValidMemberByName(name, cb)),
        checkIfValidMemberByEmail: (email, cb) => dispatch(checkIfValidMemberByEmail(email, cb)),
        setCartInfo: (totalCartProcessingFees, totalChargeAmount, totalTicketsRequested, totalCartTax, cb) => dispatch(setCartInfo(totalCartProcessingFees, totalChargeAmount, totalTicketsRequested, totalCartTax, cb)),
        getCouponInfo: (couponEntered, cb) => dispatch(getCouponInfo(couponEntered, cb)),
        getTeeTimePostingsMeta: (eventId, all, cb) => dispatch(getTeeTimePostingsMeta(eventId, all, cb)),
        getLoggedInUserCustInfoFromSellerStripe: (cb) => dispatch(getLoggedInUserCustInfoFromSellerStripe(cb))
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withSnackbar(AddPlayers)))