import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { Link } from '@material-ui/core'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { guestSuccessData } from '../store/actions/cartActions'
import { QRCode } from "react-qr-svg";
import RegisterComponent from './ui/RegisterComponent';
import LoginComponent from './ui/LoginComponent';
import SlideInModal from './ui/slideInModal';
import { TheatersOutlined } from '@material-ui/icons';

import { signUp, signIn } from '../store/actions/authenticationActions'

const styles = (theme) => ({
    root: {
        background: theme.palette.background.default,

        marginTop: '50px',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(10),
        },
    },
    indicator: {
        backgroundColor: 'transparent',
    },
    tabRoot: {
        minWidth: '100px',
        padding: 0,
        backgroundColor: 'white',
        marginLeft: theme.spacing(1),
        color: '#3F3F3F',

    },
    checkoutContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: theme.spacing(4)

    },
    header: {
        fontSize: theme.spacing(3),
        fontWeight: 700,
        color: theme.palette.text.primary
    },
    topContainer: {
        width: '100%',
        paddingBottom: theme.spacing(5),
    },
    playersContainer: {
        marginLeft: theme.spacing(2)
    },
    padding2: {
        padding: theme.spacing(2)
    },
    padding3: {
        padding: theme.spacing(3)
    },
    playerContainer: {
        marginTop: theme.spacing(2)
    },
    marginTop3: {
        marginTop: theme.spacing(3)
    },
    marginTop8: {
        marginTop: theme.spacing(8)
    },
    marginTop5: {

        marginTop: theme.spacing(5)
    },
    marginTop2: {
        marginTop: theme.spacing(2)
    },
    button: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        letterSpacing: '1px',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: 'transparent'
        }
    },
    buttonPay: {
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: theme.palette.primary.main
        }
    },
    buttonCustomDashed: {
        color: '#009BE1',
        background: 'white',
        fontWeight: 600,
        border: '2px dashed #009BE1'

    },
    marginBottom2: {
        marginBottom: theme.spacing(3)
    },
    marginBottom1: {
        marginBottom: theme.spacing(1)
    },
    bold: {
        fontWeight: '600'
    },
    muted: {
        color: theme.palette.text.disabled
    },
    bgTransparent: {
        backgroundColor: 'transparent'
    },
    smallText: {
        fontSize: theme.spacing(1.75)
    },
    center: {
        textAlign: 'center'
    },
    subText: {
        fontWeight: 500,
        paddingLeft: theme.spacing(2)
    },
    link: {
        color: theme.palette.primary.main + ' !important'
    }

});


class Success extends Component {
    state = {
        isLogin: false,
        isRegister: true,
        openModalFlag: false
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.guestSuccessData(this.props.match.params.eventId, this.props.match.params.guestId, this.props.match.params.chargeId, (data) => {
            if (!data.error) {
                this.setState({
                    successData: data.message
                })
                this.openSlide()
            }
            console.log(data)

        })
    }
    timeout = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    openSlide = async () => {
        await this.timeout(3000);
        this.setState({
            openModalFlag: true
        })
        console.log('After 5000')

    }
    registerFn = (values) => {
        this.props.register(values, (err) => {
            if (!err) {
                // redirect happens here
                console.log('Redirect')
                window.location.href = "/login.html";
            }
        })
    }

    loginFn = (values) => {
        this.props.login(values, (err) => {
            if (!err) {
                //redirect happens here 
                window.location.href = "/login.html";
            }
        })
    }

    changePage = (page) => {
        page === 'Login' ? this.setState({
            isLogin: true,
            isRegister: false
        }) :
            this.setState({
                isLogin: false,
                isRegister: true
            })
    }

    render() {
        const { classes } = this.props;

        return <div className={classes.root}>
            <Grid container className={classes.topContainer}>
                <Grid container className={classes.marginTop5} justify="center">
                    <Grid item ><Paper className={classnames(classes.bgTransparent, classes.smallText, classes.center)} ><div style={{ marginBottom: '8px' }}>Thank you for booking your tee time with us. You can find your confirmation details below. </div><div>You will also receive an email at {this.state.successData && this.state.successData.tickets[0].currentUserEmail} with the booking details</div></Paper></Grid>
                </Grid>
            </Grid>
            <Grid container wrap='wrap' spacing={5} justify='center' >
                <Grid item md={8} lg={6} xs={12} >

                    <Grid item md xs={12} className={classes.marginTop2}>
                        <Paper className={classes.padding3}>
                            <Grid container item justify="center">
                                <div className={classes.header}>Booking confirmation</div>
                            </Grid>

                            {
                                this.state.successData && this.state.successData.tickets.map((ticket, index) => {

                                    return <Grid key={index} container justify="space-between" alignItems="center" className={classes.marginTop2}>
                                        <Grid item >
                                            <Grid container direction="column" spacing={1} >
                                                <Grid item >{ticket.player.name}</Grid>
                                                <Grid item >{ticket.title} | {this.state.successData.eventInfo.eventDate}</Grid>
                                                <Grid item >1 person</Grid>
                                                <Grid item >{this.state.successData.eventInfo.eventLocation}</Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid item>
                                            <QRCode
                                                bgColor="#FFFFFF"
                                                fgColor="#000000"
                                                level="Q"
                                                style={{ width: 125 }}
                                                value={ticket.ticketId}
                                            />
                                        </Grid>

                                    </Grid>

                                })
                            }

                            <Grid container item direction="column" spacing={1} >
                                <Grid item ><Paper className={classnames(classes.bold, classes.marginTop3)}>Total Paid - ${this.state.successData && this.state.successData.totalChargeAmount}</Paper></Grid>

                            </Grid>
                        </Paper>
                    </Grid>
                    <div className={classes.playerContainer}>


                        <Grid container item justify="space-between" className={classes.padding2}>
                            <Grid item className={classes.bold}>
                                Sign Up with RB Golf <span className={classnames(classes.smallText, classes.subText)}>(RB Golf App shows all your tee times in one place so you never lose a reservation) </span>
                            </Grid>
                        </Grid>

                        <Paper className={classnames(classes.marginTop1, classes.padding2, classes.bgTransparent)}>

                            {this.state.isRegister && <RegisterComponent registerFn={this.registerFn}></RegisterComponent>}
                            {this.state.isLogin && <LoginComponent loginFn={this.loginFn}></LoginComponent>}
                            <Grid container className={classes.marginTop5} justify="center">
                                {this.state.isRegister &&
                                    <Grid item>
                                        <Paper className={classnames(classes.bgTransparent, classes.smallText)} >Already Have an Account ?
                                         <Link style={{ cursor: 'pointer' }} underline="none" className={classes.link} onClick={() => this.changePage('Login')}> Login Instead</Link>

                                        </Paper>
                                    </Grid>
                                }
                                {
                                    this.state.isLogin && <Grid item><Paper className={classnames(classes.bgTransparent, classes.smallText)} >Do not have an Account ?
                                        <Link style={{ cursor: 'pointer' }} underline="none" className={classes.link} onClick={() => this.changePage('Register')}> Register here</Link>

                                    </Paper>
                                    </Grid>
                                }

                            </Grid>



                        </Paper>

                        {
                            this.state.openModalFlag && this.state.successData.tickets.length > 2 && <SlideInModal openModal={true}>

                                <Grid container item justify="space-between" className={classes.padding2}>
                                    <Grid item className={classes.bold}>
                                        Sign Up with RB Golf <span className={classnames(classes.smallText, classes.subText)}>(RB Golf App shows all your tee times in one place so you never lose a reservation) </span>
                                    </Grid>
                                </Grid>
                                <Paper className={classnames(classes.marginTop1, classes.bgTransparent)}>

                                    {this.state.isRegister && <RegisterComponent registerFn={this.registerFn}></RegisterComponent>}
                                    {this.state.isLogin && <LoginComponent loginFn={this.loginFn}></LoginComponent>}
                                    <Grid container className={classes.marginTop5, classes.marginBottom2} justify="center">
                                        {this.state.isRegister &&
                                            <Grid item>
                                                <Paper className={classnames(classes.bgTransparent, classes.smallText)} >Already Have an Account ?
             <Link style={{ cursor: 'pointer' }} underline="none" className={classes.link} onClick={() => this.changePage('Login')}> Login Instead</Link>

                                                </Paper>
                                            </Grid>
                                        }
                                        {
                                            this.state.isLogin && <Grid item><Paper className={classnames(classes.bgTransparent, classes.smallText)} >Do not have an Account ?
            <Link style={{ cursor: 'pointer' }} underline="none" className={classes.link} onClick={() => this.changePage('Register')}> Register here</Link>

                                            </Paper>
                                            </Grid>
                                        }

                                    </Grid>



                                </Paper>
                            </SlideInModal>
                        }

                    </div>

                </Grid>
            </Grid>
        </div >
    }
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        guestSuccessData: (eventId, guestId, chargeId, cb) => dispatch(guestSuccessData(eventId, guestId, chargeId, cb)),
        register: (values, cb) => dispatch(signUp(values, cb)),
        login: (values, cb) => dispatch(signIn(values, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Success))