import React, { useState, useEffect, Fragment } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useHistory } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'

import classnames from 'classnames'
import { Link } from 'react-router-dom'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Link as MaterialLink } from '@material-ui/core'

import { withSnackbar } from 'notistack';

const styles = (theme) => ({
    error: {
        color: 'indianred'
    },
    link: {
        color: theme.palette.primary.main + ' !important',
        cursor: 'pointer'
    },
    marginTop8: {
        marginTop: '8px'
    },
    marginTop16: {
        marginTop: '16px'
    },
    marginTop8Mob: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '8px'
        }
    }
})
const useOptions = () => {
    const options = {
        style: {
            base: {
                fontFamily: 'Open Sans',
                fontSize: '14px',
                color: "#424770",
                letterSpacing: "0.025em",
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#9e2146"
            }
        }
    }

    return options;
};

const CardForm = ({ handleTokenCallback, cart, handleReserveCase, handleSelectedCardCase, classes, currentUser, cards, cardDefault, enqueueSnackbar }) => {
    console.log(currentUser)
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const history = useHistory();
    const [email, setEmail] = useState(currentUser ? currentUser.email : '');
    const [name, setName] = useState(currentUser ? currentUser.name : '')
    const [nameErr, setNameErr] = useState(undefined);
    const [clicked, setClicked] = useState(false);
    const [emailErr, setEmailErr] = useState(undefined);
    const [userWantsNewCard, setUserWantsNewCard] = useState(false);
    const [userSelectedCard, setUserSelectedCard] = useState(cardDefault);
    console.log(userSelectedCard)

    useEffect(() => {
        console.log(cart);
    }, [])

    useEffect(() => {
        console.log(userSelectedCard)
    }, [userSelectedCard])

    const validateEmail = (email) => {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    }
    const nameChanged = (e) => {
        if (name !== '') {
            setNameErr(false)
        } else {
            if (!nameErr) {
                setNameErr(true)
            }
        }
        setName(e.target.value)
    }
    const emailChanged = (e) => {
        if (validateEmail(e.target.value)) {
            setEmailErr(false);
        } else {
            if (!emailErr)
                setEmailErr(true);
        }

        setEmail(e.target.value)
    }
    const handleChange = (event) => {
        setUserSelectedCard(event.target.value);

    }
    const switchToAnotherCardOption = () => {
        setUserWantsNewCard(true)
    }
    const switchToPickCards = () => {
        setUserWantsNewCard(false)
    }

    const handleSubmit = async event => {
        event.preventDefault();
        if (cart.totalChargeAmount > 0 && name === '') {
            setNameErr(true)
        }
        if (!validateEmail(email)) {
            setEmailErr(true);
        }

        if ((cart.totalChargeAmount > 0 && name === '') || !validateEmail(email)) {
            return
        }

        // To prevent double clicks
        if (clicked) {
            return;
        }

        if (cart.totalChargeAmount > 0) {


            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            if (cards.length == 0 || userWantsNewCard) {

                const card = elements.getElement(CardElement);
                const extraDetails = {
                    name: name
                }
                const result = await stripe.createToken(card, extraDetails);

                if (result.error) {
                    // Show error to your customer.
                    enqueueSnackbar(result.error.message, { variant: 'error', persist: 'true' });

                    // console.log(result.error.message);
                } else {
                    // Send the token to your server.
                    // This function does not exist yet; we will define it in the next step.
                    //stripeTokenHandler(result.token);
                    //console.log("[Token]", result.token);
                    setClicked(true);

                    handleTokenCallback(name, result.token, email, (err) => {
                        if (err) {
                            enqueueSnackbar(err, { variant: 'error', persist: 'true' });
                            setClicked(false);
                        }
                    });



                }


            } else {
                // Defn it means that the user is picking one ! 
                console.log(userSelectedCard);
                setClicked(true);
                handleSelectedCardCase(userSelectedCard, (err) => {
                    if (err) {
                        enqueueSnackbar(err, { variant: 'error', persist: 'true' });
                        setClicked(false);
                    }
                })

            }


        } else {
            setClicked(true);
            handleReserveCase(name, email, (err) => {
                if (err) {
                    enqueueSnackbar(err, { variant: 'error', persist: 'true' });
                    setClicked(false);
                }
            });
        }

    };

    return (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
            {cart.totalChargeAmount > 0 && <Grid container alignItems="center">
                <Grid item xs={12} md={2}><Paper style={{ background: 'transparent' }} className={classes.marginTop16}>Name</Paper></Grid>
                <Grid item xs md className={classes.marginTop8}><Paper><TextField
                    disabled={currentUser && currentUser.userId ? true : false}
                    error={nameErr} helperText={nameErr ? 'Please enter name' : ''} FormHelperTextProps={{ classes: { error: classes.error } }}
                    value={name} onChange={nameChanged} InputProps={{ disableUnderline: true, spellCheck: false }} id="filled-basic-name" fullWidth placeholder={cart.totalChargeAmount > 0 ? 'Enter the name as it appears on the card' : 'Enter your name'} />
                </Paper></Grid>
            </Grid>}
            <Grid container alignItems="center" className={classes.marginTop8}>
                <Grid item xs={12} md={2} ><Paper style={{ background: 'transparent' }}>Email</Paper></Grid>
                <Grid item xs md className={classes.marginTop8}><Paper><TextField
                    disabled={currentUser && currentUser.userId ? true : false}
                    error={emailErr} helperText={emailErr ? 'Please enter a valid email' : ''} FormHelperTextProps={{ classes: { error: classes.error } }}
                    value={email} onChange={emailChanged} InputProps={{ disableUnderline: true, spellCheck: false }} id="filled-basic" fullWidth placeholder="Email for receiving Tee Times?" />
                </Paper></Grid>
            </Grid>

            {cart.totalChargeAmount > 0 && <Grid container alignItems="center" className={classes.marginTop8}>
                <Grid item xs={12} md={2}><Paper style={{ backgroundColor: 'transparent' }} >Card Details</Paper></Grid>
                {(cards.length == 0 || userWantsNewCard) && <Grid item xs className={classes.marginTop8}><Paper style={{ padding: '12px 0px' }}>  <CardElement
                    options={options}
                    onReady={() => {
                        console.log("CardElement [ready]");
                    }}
                    onChange={event => {
                        console.log("CardElement [change]", event);
                    }}
                    onBlur={() => {
                        console.log("CardElement [blur]");
                    }}
                    onFocus={() => {
                        console.log("CardElement [focus]");
                    }}
                /></Paper>
                    {currentUser && userWantsNewCard && <Grid container item justify="center"><MaterialLink className={classes.link} onClick={switchToPickCards}>or pick from cards on file</MaterialLink></Grid>}

                </Grid>}

                {(cards.length > 0 && !userWantsNewCard) &&
                    <Grid item xs md>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="cards" name="cards" value={userSelectedCard} onChange={handleChange}>
                                {
                                    cards.map((card, index) => <FormControlLabel key={index} value={card.id} control={<Radio />} label={card.brand + ' ending in ' + card.last4} />)
                                }
                            </RadioGroup>
                        </FormControl>
                        {currentUser && !userWantsNewCard && <Grid container item justify="center"><MaterialLink className={classes.link} onClick={switchToAnotherCardOption}>or use a new card</MaterialLink></Grid>}

                    </Grid>
                }

            </Grid>}

            <Grid container alignItems="center" className={classes.marginTop16}>
                {
                    cart.selectedTicketsInfo.length > 0 && cart.totalChargeAmount > 0 &&
                    <Fragment>

                        <Grid item xs={9} md={2}><Paper style={{ backgroundColor: 'transparent' }}>Order Total</Paper></Grid>
                        <Grid item xs md><Paper style={{ backgroundColor: 'transparent', fontWeight: 600 }}>${cart.totalChargeAmount}</Paper></Grid>
                    </Fragment>
                }
                {cart.selectedTicketsInfo.length > 0 && <Grid container item xs={12} md justify="flex-end" className={classes.marginTop8Mob}>

                    <Button type="submit" disabled={(cart && cart.totalChargeAmount > 0 && !stripe) || clicked} variant="contained" size="large" name="MakePaymentBtn" color="primary" >
                        {cart.totalChargeAmount > 0 ? 'Make Payment' : 'Reserve Tee Time'}
                    </Button>
                </Grid>}
            </Grid>

        </form>
    );
};

export default withStyles(styles)(withSnackbar(CardForm));
