import React from 'react';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import InfoIcon from '@material-ui/icons/Info';

import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: '12px',
    },
}))(Tooltip);

const StyledBreadcrumb = withStyles((theme) => ({
    root: {
        backgroundColor: emphasize(theme.palette.primary.main, 0.75),
        color: '#2A89FF',
        fontWeight: theme.typography.fontWeightBold,
        height: 'auto',
        '&:hover, &:focus': {
            backgroundColor: emphasize(theme.palette.primary.main, 0.75),

        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.primary.main, 0.75),
        },
        borderRadius: '6px'

    },
    label: {
        whiteSpace: 'normal',
        paddingTop: '6px',
        paddingBottom: '6px'
    }
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

export default function specialCrumb({ specialText, specialDesc }) {
    return (
        <LightTooltip title={specialDesc}>
            <Breadcrumbs aria-label="breadcrumb">

                <StyledBreadcrumb

                    label={specialText}
                    deleteIcon={<InfoIcon style={{ color: '#009be1' }} />}
                    onClick={handleClick}
                    onDelete={handleClick}
                />
            </Breadcrumbs>
        </LightTooltip>

    );
}