import { dispatchErrorWrap, dispatchSuccessWrap } from './genericActions'

import { actionTypes } from '../types'
import { constants } from '../../App.constants';

const axios = require('axios');

export const autoLoginUser = (token) => {
    return async (dispatch) => {
        let header = {
            headers: {
                'Content-Type': 'application/json',
                'login-token': token
            }
        }
        try {
            const payload = await axios.get(constants.apiUrl + "/account/", header);
            let stripeConnected;
            if (payload.data && payload.data.userId) {

                localStorage.setItem('loginToken', token);
                localStorage.setItem('email', payload.data.email);
                localStorage.setItem('userId', payload.data.userId);
                localStorage.setItem('userName', payload.data.name);
                localStorage.setItem('user', JSON.stringify(payload.data));

                let headerForStripeCheck = {
                    headers: {
                        'Content-Type': 'application/json',
                        'login-token': token
                    }
                }

                try {
                    const payloadForStripeVerification = await axios.get(constants.ticketsBaseUrl + "/connectedUser", headerForStripeCheck);
                    payloadForStripeVerification.data.connected
                        ? stripeConnected = true
                        : stripeConnected = false
                } catch (err) { }

                dispatchSuccessWrap(actionTypes.SIGN_IN, { isLoginTokenValid: true, currentUser: payload.data, stripeConnected: stripeConnected }, dispatch);

            }


        } catch{
            dispatchErrorWrap(actionTypes.SIGN_IN, { isLoginTokenValid: false, currentUser: null, stripeConnected: false }, dispatch);

        }

    }
}
export const signIn = (values, callback) => {
    return async (dispatch) => {
        try {
            let header = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': localStorage.getItem('loginToken')
                }
            }

            let reqPayload = values;

            const payload = await axios.post(constants.apiUrl + "/auth/login", reqPayload, header);
            if (payload.data) {

                localStorage.setItem('loginToken', payload.data.loginToken);
                localStorage.setItem('email', payload.data.email);
                localStorage.setItem('userId', payload.data.userId);
                localStorage.setItem('userName', payload.data.name);
                localStorage.setItem('user', JSON.stringify(payload.data));
                dispatchSuccessWrap(actionTypes.SIGN_IN, { isLoginTokenValid: true, currentUser: payload.data }, dispatch);
                callback(null);

            }

        } catch (error) {
            callback('Invalid Credentials');
            dispatchErrorWrap('Invalid Credentials', dispatch);
            //dispatchErrorWrap(actionTypes.SIGN_IN, { isLoginTokenValid: false, currentUser: null, stripeConnected: false }, dispatch);
        }

    }


}


export const signUp = (values, callback) => {
    return async (dispatch) => {
        try {
            let header = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': localStorage.getItem('loginToken')
                }
            }

            let reqPayload = values

            const payload = await axios.post(constants.apiUrl + "/auth/register", reqPayload, header);
            if (payload.data) {

                localStorage.setItem('loginToken', payload.data.loginToken);
                localStorage.setItem('email', payload.data.email);
                localStorage.setItem('userId', payload.data.userId);
                localStorage.setItem('userName', payload.data.name);
                localStorage.setItem('user', JSON.stringify(payload.data));
                dispatchSuccessWrap(actionTypes.SIGN_IN, { isLoginTokenValid: true, currentUser: payload.data }, dispatch);
                callback(null);

            }

        } catch (error) {
            callback('Invalid Credentials');
            dispatchErrorWrap('Invalid Credentials', dispatch);
            //dispatchErrorWrap(actionTypes.SIGN_IN, { isLoginTokenValid: false, currentUser: null, stripeConnected: false }, dispatch);
        }

    }


}

export const autoSignOut = () => {
    return async (dispatch) => {
        localStorage.removeItem('loginToken');
        localStorage.removeItem('email');
        localStorage.removeItem('userId');
        localStorage.removeItem('userName');
        localStorage.removeItem('user');
        // dispatchSuccessWrap(actionTypes.RESET_POSTINGS_STATE, {}, dispatch)
        dispatchSuccessWrap(actionTypes.SIGN_OUT, { isLoginTokenValid: false, currentUser: undefined }, dispatch)
    }

}

export const signOut = () => {
    return async (dispatch, getState) => {
        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': localStorage.getItem('loginToken')
                }
            }
            let reqPayload = JSON.parse(localStorage.getItem('user'));

            await axios.post(constants.apiUrl + "/auth/logout", reqPayload, headers);
            localStorage.removeItem('loginToken');
            localStorage.removeItem('email');
            localStorage.removeItem('userId');
            localStorage.removeItem('userName');
            localStorage.removeItem('user');
            //dispatchSuccessWrap(actionTypes.RESET_POSTINGS_STATE, {}, dispatch)
            dispatchSuccessWrap(actionTypes.SIGN_OUT, { isLoginTokenValid: false, currentUser: undefined }, dispatch)

        } catch (error) {
            dispatchErrorWrap(error, dispatch);
        }

    }


}

export const checkIfLoggedIn = (cb) => {
    return async (dispatch, getState) => {

        let isLoginTokenValid = false;
        let currentUser;

        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'login-token': localStorage.getItem('loginToken')
            }
        }

        try {

            const payload = await axios.get(constants.apiUrl + "/account", headers);
            //console.log('payload cal Info', payload);
            if (payload.data && payload.data.userId) {
                isLoginTokenValid = true;
                currentUser = payload.data;
                cb(payload.data)
            } else {
                isLoginTokenValid = false;
            }

        } catch (error) {
            isLoginTokenValid = false;
        }
        console.log(isLoginTokenValid);
        dispatchSuccessWrap(actionTypes.CHECK_AUTHENTICATION_TOKEN, {
            isLoginTokenValid: isLoginTokenValid,
            currentUser: currentUser
        }, dispatch)

    }
}


