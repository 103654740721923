import React, { Component, Fragment, useState, useCallback } from 'react'

import { Formik, Field, Form } from 'formik';
import FormikFieldGroup from './shared/FormikFieldGroup'
import FormikPasswordField from './shared/FormikPasswordField'

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { withStyles } from '@material-ui/core/styles';
import TeeSlot from './teeSlot'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import classnames from 'classnames'
import ToggleButton from './toggleButton'

import debounce from '../../hooks/debounce'

const styles = (theme) => ({
    padding2: {
        padding: theme.spacing(2)
    },
    padding3: {
        padding: theme.spacing(3)
    },
    playerContainer: {
        marginTop: theme.spacing(2)
    },
    marginTop3: {
        marginTop: theme.spacing(3)
    },
    marginTop8: {
        marginTop: theme.spacing(8)
    },
    marginTop5: {

        marginTop: theme.spacing(5)
    },
    marginTop2: {
        marginTop: theme.spacing(2)
    },
    button: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        letterSpacing: '1px',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: 'transparent'
        }
    },
    buttonPay: {
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: theme.palette.primary.main
        }
    },
    buttonCustomDashed: {
        color: '#009BE1',
        background: 'white',
        fontWeight: 600,
        border: '2px dashed #009BE1'

    },
    marginBottom2: {
        marginBottom: theme.spacing(3)
    },
    marginBottom1: {
        marginBottom: theme.spacing(1)
    },
    bold: {
        fontWeight: '600'
    },
    muted: {
        color: theme.palette.text.disabled
    },
    bgTransparent: {
        backgroundColor: 'transparent'
    },
    smallText: {
        fontSize: theme.spacing(1.75)
    },
    center: {
        textAlign: 'center'
    },
    subText: {
        fontWeight: 500,
        paddingLeft: theme.spacing(2)
    },
    link: {
        color: theme.palette.primary.main + ' !important',
        cursor: 'pointer'
    }


});



const RegisterComponent = ({ classes, registerFn }) => {
    const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    const PASSWORD_REGEX = /^\S.{4,28}\S$/
    const handleRegister = (values, {
        setFieldError
    }) => {
        registerFn(values);

    }

    const validate = values => {
        const errors = {}

        if (!values.name) {
            errors.name = 'Name is required'
        } else if (values.name.trim().length < 3) {
            errors.name = "The name should be at least 3 characters long."
        } else if (values.name.trim().length > 40) {
            errors.name = "The name should be 40 characters long at the most."
        }

        if (!values.email) {
            errors.email = "Email is required."
        } else if (!EMAIL_REGEX.test(values.email)) {
            errors.email = "Invalid email address."
        }

        if (!values.password) {
            errors.password = "The password is required."
        } else if (values.password.length < 6) {
            errors.password = "The password should be at least 6 characters long."
        } else if (values.password.length > 30) {
            errors.password = "The password should be 30 characters long at the most."
        } else if (!PASSWORD_REGEX.test(values.password)) {
            errors.password = "Leading and trailing white spaces are not allowed."
        }

        return errors
    }


    return <div>
        <Formik
            initialValues={{
                name: '',
                email: '',
                password: '',
            }}
            onSubmit={handleRegister}
            validate={validate}
        >
            <Form>

                <Paper className={classnames(classes.marginTop1, classes.padding2, classes.bgTransparent)}>

                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs><Paper>  <FormikFieldGroup
                            label='Name'
                            name='name'
                            type='text'
                            placeholder='Please enter name'
                            elevation={2}
                            padding={12}
                        /></Paper></Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs><Paper><FormikFieldGroup
                            label='Email'
                            name='email'
                            type='text'
                            placeholder='Please enter email'
                            elevation={2}
                            padding={12}
                        /></Paper></Grid>
                    </Grid>

                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs><Paper><FormikPasswordField label={'password'} /></Paper></Grid>
                    </Grid>
                    <Grid container className={classes.marginTop5} justify="center">
                        <Grid item><Paper className={classnames(classes.bgTransparent, classes.smallText)} >By Creating your account you agree to <Link className={classes.link}>Terms & Conditions</Link> as well as our <Link className={classes.link}>privacy policy</Link></Paper></Grid>
                    </Grid>

                    <Grid container className={classes.marginTop2} justify="center">
                        <Grid item>
                            <Button type="submit" variant="contained" size="large" name="MakePaymentBtn" color="primary" className={classes.buttonPay} >
                                Create Account
                         </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Form>
        </Formik>
    </div>

}


export default withStyles(styles)(RegisterComponent)