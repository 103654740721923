import React, { Component } from 'react'

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { withStyles } from '@material-ui/core/styles';
import TeeSlot from './ui/teeSlot'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import CardForm from './ui/cardForm';

import { connect } from 'react-redux';
import { guestPurchase, guestSuccessEmail, allcalUserPurchase, allcalUserSuccessEmail, allcalUserPurchaseWithSelectedCard, allcalPaypalPurchase, guestPaypalPurchase } from '../store/actions/cartActions'
import moment from 'moment'
import PaypalButton from './payPalButton'

import { withSnackbar } from 'notistack';

const styles = (theme) => ({
    root: {
        background: theme.palette.background.default,

        marginTop: '50px',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0),
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(10),
            paddingBottom: theme.spacing(10),
        },
    },
    indicator: {
        backgroundColor: 'transparent',
    },
    divider: {
        border: '1px solid #80808036',
        width: '100%'
    },
    tabRoot: {
        minWidth: '100px',
        padding: 0,
        backgroundColor: 'white',
        marginLeft: theme.spacing(1),
        color: '#3F3F3F',

    },
    checkoutContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: theme.spacing(4)

    },
    header: {
        fontSize: theme.spacing(3),
        fontWeight: 700,
        color: theme.palette.text.primary,
        textTransform: 'uppercase'
    },
    topContainer: {
        width: '100%',
        paddingBottom: theme.spacing(5),
    },
    playersContainer: {
        marginLeft: theme.spacing(2)
    },
    padding2: {
        padding: theme.spacing(2),
    },
    padding2LargeScreen: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: '16px 0'
        }
    },


    playerContainer: {
        marginTop: theme.spacing(2)
    },
    marginTop3: {
        marginTop: theme.spacing(3)
    },
    marginBottom4: {
        marginBottom: theme.spacing(4)
    },

    marginBottom3: {
        marginBottom: theme.spacing(3)
    },
    marginTop8: {
        marginTop: theme.spacing(8)
    },
    marginTop5: {

        marginTop: theme.spacing(5)
    },
    marginTop2: {
        marginTop: theme.spacing(2)
    },
    button: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        letterSpacing: '1px',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: 'transparent'
        }
    },
    buttonPay: {
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: theme.palette.primary.main
        }
    },
    buttonCustomDashed: {
        color: '#009BE1',
        background: 'white',
        fontWeight: 600,
        border: '2px dashed #009BE1'

    },
    marginBottom2: {
        marginBottom: theme.spacing(3)
    },
    marginBottom1: {
        marginBottom: theme.spacing(1)
    },
    bold: {
        fontWeight: '600'
    },
    muted: {
        color: theme.palette.text.disabled
    },
    bgTransparent: {
        background: 'transparent'
    },
    link: {
        textDecoration: 'none'
    },
    subText: {
        fontSize: theme.spacing(1.75)
    },

    red: {
        color: 'indianred'
    },
    green: {
        color: theme.palette.success.main
    },
    paddingLeft2: {
        paddingLeft: theme.spacing(2)
    },
    marginTop10Mob: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
        }
    }


});


class PayNow extends Component {

    onSuccess = (payment) => {
        // Congratulation, it came here means everything's fine!
        if (payment.paid) {
            if (this.props.currentUser) {
                this.props.allcalPaypalPurchase(this.props.currentUser.name, this.props.currentUser.userId, this.props.cart, this.props.cart.eventMeta.eventId, payment, (err, successData) => {
                    if (err) {
                        //cb(err);
                        this.props.enqueueSnackbar('Error issuing tickets', { variant: 'error' });

                    } else {
                        this.props.allcalUserSuccessEmail(payment.email, this.props.cart.eventMeta.eventId, successData.transId, successData.charge, this.props.cart, () => {

                            //this.props.history.push('/event/' + this.props.cart.eventMeta.eventId + '/trans/' + successData.transId + '/charge/' + successData.charge + '/success');
                            /*window.location.href = "/login.html?success=" + successData.transId;*/
                            window.location.href = "https://www.rbgolf.com/myaccount#/?success=" + successData.transId;
                        });
                    }


                });
            } else {
                this.props.guestPaypalPurchase(payment.email, payment.email, this.props.cart, this.props.cart.eventMeta.eventId, payment, (err, successData) => {
                    if (err) {
                        //cb(err);
                        this.props.enqueueSnackbar('Error issuing tickets', { variant: 'error' });

                    } else {
                        this.props.guestSuccessEmail(this.props.cart.eventMeta.eventId, successData.guestId, successData.charge, this.props.cart, () => {

                            this.props.history.push('/event/' + this.props.cart.eventMeta.eventId + '/guest/' + successData.guestId + '/charge/' + successData.charge + '/success');

                        });
                    }


                });
            }
        } else {
            this.props.enqueueSnackbar('Payment unsuccessful', { variant: 'error' });
        }

        // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
    }

    onCancel = (data) => {
        // User pressed "cancel" or close Paypal's popup!
        console.log('The payment was cancelled!', data);
        this.props.enqueueSnackbar('Payment cancelled', { variant: 'info' });

        // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    }

    onError = (err) => {
        // The main Paypal's script cannot be loaded or somethings block the loading of that script!
        console.log("Error!", err);
        // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
        // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    }

    handleReserveCase = (name, email, cb) => {
        console.log('Guest get');

        if (email && email != '') {
            if (this.props.currentUser) {
                this.allcalUserPurchaseFn(email, this.props.currentUser.userId, undefined, cb);
            } else {
                this.guestPurchase(name, email, undefined, cb);
            }
        }
    }

    handleTokenCallback = (name, token, email, cb) => {
        //this.props.history.push('success');
        console.log(token, email)
        if (token && token.id && email && email != '') {
            if (this.props.currentUser) {
                this.allcalUserPurchaseFn(email, this.props.currentUser.userId, token, cb);
            } else {
                this.guestPurchase(name, email, token, cb);
            }
        }
    }

    handleSelectedCardCase = (selectedCard, cb) => {
        if (selectedCard) {
            this.allcalUserSelectedCardPurchaseFn(selectedCard, this.props.currentUser.userId, cb)
        }
    }


    guestPurchase = (name, email, token, cb) => {
        this.props.guestPurchase(name, email, token, this.props.cart, this.props.cart.eventMeta.eventId, this.props.cart.totalChargeAmount > 0 ? 'paid' : 'free', (err, successData) => {
            console.log(successData);
            if (err) {
                cb(err);
            } else {
                this.props.guestSuccessEmail(this.props.cart.eventMeta.eventId, successData.guestId, successData.charge, this.props.cart, () => {

                    this.props.history.push('/event/' + this.props.cart.eventMeta.eventId + '/guest/' + successData.guestId + '/charge/' + successData.charge + '/success');

                });
            }


        });
    }

    allcalUserPurchaseFn = (email, buyerId, token, cb) => {
        this.props.allcalUserPurchase(this.props.currentUser.name, buyerId, token, this.props.cart, this.props.cart.eventMeta.eventId, this.props.cart.totalChargeAmount > 0 ? 'paid' : 'free', (err, successData) => {
            console.log(successData);
            if (err) {
                cb(err);
            } else {
                this.props.allcalUserSuccessEmail(email, this.props.cart.eventMeta.eventId, successData.transId, successData.charge, this.props.cart, () => {

                    //this.props.history.push('/event/' + this.props.cart.eventMeta.eventId + '/trans/' + successData.transId + '/charge/' + successData.charge + '/success');
                    window.location.href = "/login.html?success=" + successData.transId;
                });
            }


        });
    }

    allcalUserSelectedCardPurchaseFn = (selectedCard, buyerId, cb) => {
        this.props.allcalUserPurchaseWithSelectedCard(this.props.currentUser.name, buyerId, selectedCard, this.props.cart, this.props.cart.eventMeta.eventId, 'paid', (err, successData) => {
            console.log(successData);
            if (err) {
                cb(err);
            } else {
                this.props.allcalUserSuccessEmail(this.props.currentUser.email, this.props.cart.eventMeta.eventId, successData.transId, successData.charge, this.props.cart, () => {

                    //this.props.history.push('/event/' + this.props.cart.eventMeta.eventId + '/trans/' + successData.transId + '/charge/' + successData.charge + '/success');
                    window.location.href = "/login.html?success=" + successData.transId;
                });
            }


        });
    }

    computePriceForGivenAmt = (ticketAmt) => {
        //   ticketAmt + this.computeProcessingFee(ticketAmt) + 
        ticketAmt = parseFloat((ticketAmt).toFixed(2));
        let ticketAmtWithProcFee = ticketAmt + this.computeProcessingFee(ticketAmt);
        let totalTaxAmount = 0;

        if (this.props.includeTax && this.props.taxPercent > 0 && !this.props.taxInclusiveOnFee) {
            //Calculating tax on already rounded off noFeeAmount i.e calculating tax on rounded off to 2 digits noFeeAmount
            ticketAmt = parseFloat((ticketAmt).toFixed(2));
            totalTaxAmount = (ticketAmt * ((this.props.taxPercent) / 100));

            return ticketAmtWithProcFee +
                parseFloat((totalTaxAmount).toFixed(2));


        } else if (this.props.includeTax && this.props.taxPercent > 0 && this.props.taxInclusiveOnFee) {

            totalTaxAmount = (ticketAmtWithProcFee * ((this.props.taxPercent) / 100));
            return ticketAmtWithProcFee +
                parseFloat((totalTaxAmount).toFixed(2));


        } else {
            return ticketAmtWithProcFee;
        }


    }
    computeProcessingFee = (ticketAmt) => {
        let processingFee = 0;
        if (ticketAmt === 0) {
            processingFee = 0;
        } else {
            processingFee = 2;
        }
        if (ticketAmt > 0 && ticketAmt < 38) {
            processingFee = 2;
        }
        if (ticketAmt >= 38 && ticketAmt < 58) {
            processingFee = 3;
        }
        if (ticketAmt >= 58 && ticketAmt < 78) {
            processingFee = 4;
        }
        if (ticketAmt >= 78 && ticketAmt < 98) {
            processingFee = 5;
        }
        if (ticketAmt >= 98) {
            processingFee = 6 + ((ticketAmt - 98) * 4);
        }
        return processingFee;
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        console.log(this.props.cart)
        console.log(this.props.cards)

    }
    render() {
        const { classes, cart } = this.props;
        const { totalChargeAmount, totalCartProcessingFees, totalCartTax, postingMeta, eventMeta } = this.props.cart;

        let payLaterInPlay = false;
        this.props.cart.selectedTicketsInfo.forEach((playerInfo, index) => {
            if (playerInfo.payLater) {
                payLaterInPlay = true;
            }
        })
        return <div className={classes.root}>
            <Grid container justify="center" className={classes.marginTop10Mob}>
                <Grid item lg={9} md xs >
                    <div className={classnames(classes.header, classes.marginBottom3)}>Book Tee Times</div>
                </Grid>
            </Grid>
            <Grid container wrap='wrap' justify="center">
                <Grid item lg={6} md={8} xs={12} >
                    <Paper className={classnames(classes.marginTop1, classes.padding2LargeScreen)}>

                        <Grid item className={classnames(classes.marginBottom2)}><Paper className={classes.bold}>Tee Time Booking overview</Paper></Grid>

                        <Grid item ><Paper className={classnames(classes.bold, classes.marginBottom1)}>{postingMeta.title}</Paper></Grid>
                        <Grid item ><Paper>{moment(eventMeta.startDate).format('MMM D')}</Paper></Grid>

                        <Grid item ><Paper className={classnames(classes.marginTop3, classes.muted, classes.marginBottom2)}>Players</Paper></Grid>

                        {
                            this.props.cart.selectedTicketsInfo.map((playerInfo, index) => {
                                return <Grid key={index} container justify="space-between" className={classes.marginBottom2}>
                                    <Grid item >
                                        {playerInfo.player && playerInfo.player.name && <Grid item className={classes.paddingBottom1}><Paper>{playerInfo.player.name}</Paper></Grid>}

                                        {/*!playerInfo.player.validMember && <Grid item ><Paper><span className={classnames(classes.red, classes.subText)}>Non Member</span></Paper></Grid>*/}

                                        {/*playerInfo.player.validMember && <Grid item ><Paper><span className={classnames(classes.green, classes.subText)}>Valid Member</span></Paper></Grid>*/}
                                    </Grid>

                                    {playerInfo.payLater ? <Grid item className={classes.subText}>send invoice (${this.computePriceForGivenAmt(playerInfo.payLaterMeta.postingOneTicketPriceWithOutFees)})</Grid> : <Grid item> ${playerInfo.postingOneTicketPriceWithOutFees}</Grid>}


                                </Grid>
                            })
                        }


                        {
                            totalChargeAmount > 0 ?
                                <Grid container >

                                    <Grid container justify="space-between" alignItems="center" className={classes.marginTop5}>
                                        <Grid item >
                                            <Grid item ><Paper><span className={classnames(classes.muted)}>Processing Fee</span></Paper></Grid>

                                        </Grid>
                                        <Grid item>
                                            <Grid item > ${totalCartProcessingFees}</Grid>
                                        </Grid>

                                    </Grid>
                                    {
                                        this.props.includeTax && this.props.taxPercent > 0 && <Grid container justify="space-between" alignItems="center" className={classes.marginTop2}>
                                            <Grid item >
                                                <Grid item ><Paper><span className={classnames(classes.muted)}>Tax ({this.props.taxPercent} %)</span></Paper></Grid>

                                            </Grid>
                                            <Grid item>
                                                <Grid item > ${totalCartTax}</Grid>
                                            </Grid>

                                        </Grid>
                                    }

                                    <Grid container justify="space-between" alignItems="center" className={classes.marginTop2}>
                                        <Grid item >
                                            <Grid item ><Paper><span className={classnames(classes.muted)}>{payLaterInPlay ? 'Due Now' : 'Total'} </span></Paper></Grid>

                                        </Grid>
                                        <Grid item>
                                            <Grid item style={{ fontWeight: 600 }}> ${totalChargeAmount}</Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                :

                                <Grid container justify="space-between" alignItems="center" className={classes.marginTop5}>
                                    <Grid item >
                                        <Grid item ><Paper><span className={classnames(classes.muted)}>{payLaterInPlay ? 'Due Now' : 'Total'}</span></Paper></Grid>

                                    </Grid>
                                    <Grid item>
                                        <Grid item style={{ fontWeight: 600 }}> $0  </Grid>
                                    </Grid>

                                </Grid>

                        }
                    </Paper>
                </Grid>

            </Grid>

            <Grid container wrap='wrap' justify="center">
                <Grid item lg={6} md={8} xs={12} >


                    <div className={classes.playerContainer}>
                        <Grid container item justify="space-between" className={classes.padding2LargeScreen}>
                            <Grid item className={classes.bold}>
                                Tee Time Confirmation
                            </Grid>

                        </Grid>

                        <Paper className={classnames(classes.marginTop1, classes.padding2LargeScreen, classes.bgTransparent)}>

                            <CardForm cards={this.props.cards} cardDefault={this.props.cardDefault} handleTokenCallback={this.handleTokenCallback} handleReserveCase={this.handleReserveCase} handleSelectedCardCase={this.handleSelectedCardCase} cart={cart} currentUser={this.props.currentUser}></CardForm>

                        </Paper>

                    </div>
                    <div>
                        <Grid container item>

                            <hr className={classes.divider}></hr>
                        </Grid>
                    </div>
                    <div>
                        {
                            cart && cart.totalChargeAmount > 0 && <Grid container justify="space-between">
                                <Grid item className={classnames(classes.bold, classes.paddingLeft2)} >
                                    Pay with Paypal
                                </Grid>


                                <PaypalButton total={cart.totalChargeAmount} onSuccessCb={this.onSuccess} onErrorCb={this.onError} onCancelCb={this.onCancel}></PaypalButton>
                            </Grid>
                        }

                    </div>



                </Grid>
            </Grid>
        </div >
    }
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart,
        cards: state.cart.cards,
        cardDefault: state.cart.cardDefault,

        taxPercent: state.meta.taxPercent,
        taxInclusiveOnFee: state.meta.taxInclusiveOnFee,
        includeTax: state.meta.includeTax
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        guestPurchase: (name, email, stripeToken, cart, eventId, ticketType, cb) => dispatch(guestPurchase(name, email, stripeToken, cart, eventId, ticketType, cb)),
        guestSuccessEmail: (eventId, guestId, chargeId, cart, cb) => dispatch(guestSuccessEmail(eventId, guestId, chargeId, cart, cb)),
        allcalUserSuccessEmail: (email, eventId, transId, chargeId, cart, cb) => dispatch(allcalUserSuccessEmail(email, eventId, transId, chargeId, cart, cb)),
        allcalUserPurchase: (name, userIdToConsider, stripeToken, cart, eventId, ticketType, cb) => dispatch(allcalUserPurchase(name, userIdToConsider, stripeToken, cart, eventId, ticketType, cb)),
        allcalUserPurchaseWithSelectedCard: (name, buyerId, selectedCard, cart, eventId, ticketType, cb) => dispatch(allcalUserPurchaseWithSelectedCard(name, buyerId, selectedCard, cart, eventId, ticketType, cb)),
        allcalPaypalPurchase: (name, userIdToConsider, cart, eventId, paypalChargeData, cb) => dispatch(allcalPaypalPurchase(name, userIdToConsider, cart, eventId, paypalChargeData, cb)),
        guestPaypalPurchase: (name, email, cart, eventId, paypalChargeData, cb) => dispatch(guestPaypalPurchase(name, email, cart, eventId, paypalChargeData, cb))
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withSnackbar(PayNow)))
