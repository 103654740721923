import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import FormikFieldGroup from './FormikFieldGroup'

const FormikPasswordField = ({ name, label, placeholder }) => {
    const [shouldShowPassword, setShouldShowPassword] = useState(false)

    const handleClickShowPassword = () => {
        setShouldShowPassword(!shouldShowPassword)
    }

    const handleMouseDownPassword = ev => ev.preventDefault()

    return <FormikFieldGroup
        label={label}
        name={name || 'password'}
        type={shouldShowPassword ? 'text' : 'password'}
        placeholder={placeholder}
        endAdornment={
            <InputAdornment position='end'>
                <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    data-test-id='toggle-visibility-icon'
                >
                    {shouldShowPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            </InputAdornment>
        }
        elevation={2}
        padding={12}
    />
}

FormikPasswordField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string
}

export default FormikPasswordField