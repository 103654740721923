import React, { Component } from 'react'
import { PageView, initGA } from './Tracking';

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { withStyles } from '@material-ui/core/styles';
import TeeSlot from '../components/ui/teeSlot'
import Grid from '@material-ui/core/Grid'
import ToggleButton from './ui/toggleButton'

import { connect } from 'react-redux';
import { getUpcomingDates, getTeeTimePostingsMeta, setDayIndex } from '../store/actions/teeTimePostingActions'
import { clearCartInfo } from '../store/actions/cartActions'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = (theme) => ({
    root: {
        marginTop: '50px',
        width: '100%',
        background: theme.palette.background.default
    },
    indicator: {
        backgroundColor: 'transparent',
    },
    tabRoot: {
        minWidth: '100px',
        padding: 0,
        backgroundColor: 'white',
        marginLeft: theme.spacing(1),
        color: '#3F3F3F',

    },
    teeSlot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'

    },
    header: {
        fontSize: theme.spacing(3),
        fontWeight: 700,
        color: theme.palette.text.primary,
        textTransform: 'uppercase'
    },
    topContainer: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(6)
        },

    },
    toggle: {
        "& .MuiToggleButton-root": {
            border: 0,
            color: theme.palette.text.primary,
            minWidth: theme.spacing(10),
            backgroundColor: 'white',
            textTransform: 'capitalize'
        },
        "& .Mui-selected": {
            backgroundColor: theme.palette.primary.main + ' !important',
            color: 'white',
            fontWeight: 600
        }
    },
    marginBottom5: {
        marginBottom: theme.spacing(5)
    }


});


class BookTee extends Component {
    state = {
        all: false,
        loading: false
    }

    componentDidMount() {
        initGA('UA-124067051-2')
        PageView('/new-teetimes');
        this.setLoading()
        this.props.getUpcomingDates((err, days) => {
            if (days && days.length > 0) {
                this.props.getTeeTimePostingsMeta(days[this.props.selectedDayIndex].eventId, this.state.all, () => {

                    this.setLoading()
                })
            }else{
                this.setLoading()
            }

        })
    }
    setLoading = () => {
        this.setState({
            loading: !this.state.loading
        })
    }
    a11yProps = (index) => {
        return {
            id: `scrollable-force-tab-${index}`,
            "aria-controls": `scrollable-force-tabpanel-${index}`
        };
    }
    handleChange = (e, index) => {
        console.log(index)

        this.setLoading()
        this.props.setDayIndex(index, () => {
            if (this.props.days && this.props.days.length > 0) {
                this.setState({
                    all: false
                }, () => {
                    this.props.getTeeTimePostingsMeta(this.props.days[index].eventId, this.state.all, () => {

                        this.setLoading()
                    });
                })

            }
        });



    }
    bookSlotClicked = () => {
        console.log('slot clicked')
        this.props.clearCartInfo();
    }
    onSelectToggle = (val) => {

        this.setLoading()
        this.setState({
            all: val
        }, () => {
            this.props.getTeeTimePostingsMeta(this.props.days[this.props.selectedDayIndex].eventId, this.state.all, () => {

                this.setLoading()
            });
        })

    }
    render() {
        const { classes } = this.props;
        console.log(this.props.days);
        console.log(this.props.meta);

        return <div className={classes.root}>
            <Grid container className={classes.topContainer}>
                <Grid item>
                    <div className={classes.header}>Book Tee Times</div>
                </Grid>

            </Grid>
            <Grid container>
                <Grid item xs md={10} className={classes.marginBottom5}>
                    <Tabs
                        value={this.props.selectedDayIndex}
                        onChange={this.handleChange}
                        classes={{
                            indicator: classes.indicator
                        }}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="scrollable force tabs example"
                    >
                        {
                            this.props.days.map((day, index) => {
                                return <Tab
                                    key={index}
                                    className={classes.tabRoot}
                                    icon={
                                        <div style={{ textTransform: 'capitalize' }}>
                                            <div style={{ fontSize: '28px', fontWeight: 700 }}>{moment(day.startDate).format('D')}</div>
                                            <div style={{ fontSize: '16px', fontWeight: 700 }}>{moment(day.startDate).format('MMM')}</div>
                                            <div>{moment(day.startDate).format('dddd')}</div>
                                        </div>
                                    }
                                    {...this.a11yProps(index)}
                                />
                            })

                        }

                    </Tabs>
                </Grid>
                <Grid container item xs={12} md={2} justify="center">
                    <div className={classes.toggle}>
                        <ToggleButton yesLabel="All" noLabel="Specials" defaultVal={true} value={this.state.all} onSelect={this.onSelectToggle}></ToggleButton>
                    </div>
                </Grid>
            </Grid>

            {
                !this.state.loading ? <div className={classes.teeSlot}>
                    {this.props.meta && this.props.meta.length > 0 ? this.props.meta.map((ticketInfo, index) => <TeeSlot key={index} event={ticketInfo.event} posting={ticketInfo.posting} availability={ticketInfo.ticketsRemaining} slot={ticketInfo.title} price={'$' + ticketInfo.ticketAmount + ' per person'} bookSlotClicked={this.bookSlotClicked} isSpecial={ticketInfo.markAsSpecial} specialText={ticketInfo.specialText} specialDesc={ticketInfo.specialDesc}></TeeSlot>
                    ) : <div>No Tee Times available</div>}

                </div> : <Grid container item justify="center" ><CircularProgress ></CircularProgress></Grid>
            }

        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        days: state.days.data,
        selectedDayIndex: state.days.selectedDayIndex,
        meta: state.meta.data
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        getUpcomingDates: (cb) => dispatch(getUpcomingDates(cb)),
        getTeeTimePostingsMeta: (eventId, all, cb) => dispatch(getTeeTimePostingsMeta(eventId, all, cb)),
        setDayIndex: (index, cb) => dispatch(setDayIndex(index, cb)),
        clearCartInfo: () => dispatch(clearCartInfo())
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BookTee))