import React, { Component } from 'react'

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { withStyles } from '@material-ui/core/styles';
import TeeSlot from './ui/teeSlot'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import classnames from 'classnames'

const styles = (theme) => ({
    root: {
        background: theme.palette.background.default,
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(10),
        },
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    marginTop10Xs: {
        marginTop: theme.spacing(10),

    },
    indicator: {
        backgroundColor: 'transparent',
    },
    tabRoot: {
        minWidth: '100px',
        padding: 0,
        backgroundColor: 'white',
        marginLeft: theme.spacing(1),
        color: '#3F3F3F',

    },
    checkoutContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: theme.spacing(4)

    },
    header: {
        fontSize: theme.spacing(3),
        fontWeight: 700,
        color: theme.palette.text.primary,
        textTransform: 'uppercase'
    },
    topContainer: {
        width: '100%',
        paddingBottom: theme.spacing(5),
    },
    playersContainer: {
        marginLeft: theme.spacing(2)
    },
    padding2: {
        padding: theme.spacing(2)
    },
    marginTop3: {
        marginTop: theme.spacing(3)
    },
    marginTop8: {
        marginTop: theme.spacing(8)
    },
    marginTop5: {

        marginTop: theme.spacing(5)
    },
    marginTop2: {
        marginTop: theme.spacing(2)
    },
    button: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        letterSpacing: '1px',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: 'transparent'
        }
    },
    buttonPay: {
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: theme.palette.primary.main
        }
    },
    buttonCustomDashed: {
        color: '#009BE1',
        background: 'white',
        fontWeight: 600,
        border: '2px dashed #009BE1'

    },
    marginBottom2: {
        marginBottom: theme.spacing(3)
    },
    marginBottom1: {
        marginBottom: theme.spacing(1)
    },
    bold: {
        fontWeight: '600'
    },
    muted: {
        color: theme.palette.text.disabled
    },
    bgTransparent: {
        background: 'transparent'
    },
    bgWhite: {
        background: 'white'
    },
    bgDefault: {
        background: theme.palette.background.default
    },
    padding5: {
        padding: theme.spacing(5)
    },
    paddingTopBottom3: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    }



});


class myAccount extends Component {

    render() {
        const { classes } = this.props;

        return <div className={classes.root}>

            <Grid container item wrap='wrap' spacing={5} justify="center" className={classnames(classes.bgWhite, classes.marginTop10Xs, classes.paddingTopBottom3)} xs={12} md={8}>
                <Grid container item justify="space-between" >
                    <Grid item className={classnames(classes.bold)} >
                        <paper className={classnames(classes.bgTransparent)}>Account Details</paper>
                    </Grid>

                </Grid>
                <Grid item md={8} xs={12}>

                    <Paper className={classnames(classes.bgTransparent)}>

                        <Grid container className={classes.marginTop2} alignItems="center" >
                            <Grid item xs={12} sm={2} ><Paper className={classes.bgTransparent}>Name</Paper></Grid>
                            <Grid item xs><Paper className={classes.bgDefault}><TextField InputProps={{ disableUnderline: true }} id="filled-basic" fullWidth placeholder="Enter name" value="Jane Doe" /></Paper></Grid>
                        </Grid>
                        <Grid container className={classes.marginTop2} alignItems="center">
                            <Grid item xs={12} sm={2}><Paper className={classes.bgTransparent}>Member ID</Paper></Grid>
                            <Grid item xs><Paper className={classes.bgDefault}><TextField InputProps={{ disableUnderline: true }} id="filled-basic" fullWidth value="1021-1" /></Paper></Grid>
                        </Grid>

                        <Grid container className={classes.marginTop2} alignItems="center">
                            <Grid item xs={12} sm={2}><Paper className={classes.bgTransparent}>Email</Paper></Grid>
                            <Grid item xs><Paper className={classes.bgDefault}><TextField InputProps={{ disableUnderline: true }} id="filled-basic" fullWidth value="janedoe@allcal.com" /></Paper></Grid>
                        </Grid>
                        <Grid container className={classes.marginTop2} alignItems="center">
                            <Grid item xs={12} sm={2}><Paper className={classes.bgTransparent}>Date of Birth</Paper></Grid>
                            <Grid item xs><Paper className={classes.bgDefault}><TextField InputProps={{ disableUnderline: true }} id="filled-basic" fullWidth placeholder="Enter Date of Birth" /></Paper></Grid>
                        </Grid>

                        <Grid container className={classes.marginTop2} alignItems="center">
                            <Grid item xs={12} sm={2}><Paper className={classes.bgTransparent}>Phone</Paper></Grid>
                            <Grid item xs><Paper className={classes.bgDefault}><TextField InputProps={{ disableUnderline: true }} id="filled-basic" fullWidth placeholder="Enter Phone" /></Paper></Grid>
                        </Grid>
                        <Grid container className={classes.marginTop2} alignItems="center">
                            <Grid item xs={12} sm={2}><Paper className={classes.bgTransparent}>Address</Paper></Grid>
                            <Grid item xs><Paper className={classes.bgDefault}><TextField InputProps={{ disableUnderline: true }} id="filled-basic" fullWidth placeholder="Enter Address" /></Paper></Grid>
                        </Grid>
                        <Grid container className={classes.marginTop2} alignItems="center">
                            <Grid item xs={12} sm={2}><Paper className={classes.bgTransparent}>Emergency Contact Name</Paper></Grid>
                            <Grid item xs><Paper className={classes.bgDefault}><TextField InputProps={{ disableUnderline: true }} id="filled-basic" fullWidth placeholder="Enter Emergency Contact Name" /></Paper></Grid>
                        </Grid>
                        <Grid container className={classes.marginTop2} alignItems="center">
                            <Grid item xs={12} sm={2}><Paper className={classes.bgTransparent}>Emergency Contact Phone</Paper></Grid>
                            <Grid item xs><Paper className={classes.bgDefault}><TextField InputProps={{ disableUnderline: true }} id="filled-basic" fullWidth placeholder="Enter Emergency Contact Phone" /></Paper></Grid>
                        </Grid>
                        <Grid container className={classes.marginTop2} alignItems="center">
                            <Grid item xs={12} sm={2}><Paper className={classes.bgTransparent}>Member Since</Paper></Grid>
                            <Grid item xs><Paper className={classes.bgDefault}><TextField InputProps={{ disableUnderline: true }} id="filled-basic" fullWidth value="Jan 21, 2020" /></Paper></Grid>
                        </Grid>

                    </Paper>


                </Grid>
                <Grid item md xs={12} className={classes.marginTop2}>
                    <Paper className={classnames(classes.padding2, classes.bgDefault)}>
                        <Grid container item direction="column" >
                            <Grid container direction="column" spacing={1} >
                                <Grid item className={classes.bold}>Membership</Grid>
                                <Grid item >RB Golf Friends and Family</Grid>
                            </Grid>

                            <Grid container direction="column" spacing={1} >
                                <Grid item ><Paper className={classnames(classes.bold, classes.marginTop3, classes.bgDefault)}>Cards on File</Paper></Grid>
                                <Grid item ><Paper className={classes.bgDefault}>visa ending in 5558</Paper></Grid>

                            </Grid>

                            <Grid container direction="column" spacing={1} >
                                <Grid item ><Paper className={classnames(classes.bold, classes.marginTop3, classes.bgDefault)}>Upcoming Invoice</Paper></Grid>
                                <Grid item ><Paper className={classes.bgDefault}>June 10</Paper></Grid>

                            </Grid>


                            <Grid container direction="column" spacing={1} >
                                <Grid item ><Paper className={classnames(classes.bold, classes.marginTop3, classes.bgDefault)}>Pending Member Charges</Paper></Grid>
                                <Grid item ><Paper className={classes.bgDefault}>2$ test charge</Paper></Grid>

                            </Grid>


                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    }
}


export default withStyles(styles)(myAccount)