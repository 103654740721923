import cartReducer from './cartReducer';
import teeTimePostingReducer from './teeTimePostingReducer'
import daysListReducer from './daysListReducer'
import { combineReducers } from 'redux';
import authenticationReducers from './authenticationReducers';


const rootReducer = combineReducers({
    cart: cartReducer,
    meta: teeTimePostingReducer,
    days: daysListReducer,
    authentication: authenticationReducers
});

export default rootReducer