import { actionTypes } from '../types'

const initState = {
    data: [],
    selectedDayIndex: 0
};

const daysListReducer = (state = initState, action) => {

    switch (action.type) {
        case actionTypes.GET_UPCOMING_DATES: {
            let newState = {};
            newState = { ...state, data: action.payload.events }
            return newState
        }
        case actionTypes.UPDATE_DAY_INDEX: {
            let newState = {};
            newState = { ...state, selectedDayIndex: action.payload.index }
            return newState
        }
        default:
            {
                return state
            }

    }

}

export default daysListReducer
