
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkIfLoggedIn } from '../../store/actions/authenticationActions'
import { updateSelectedTicketsInfo } from '../../store/actions/cartActions'
import queryString from 'query-string';

export default function (ComposedComponent) {
    //console.log("Inside HOC");


    class Authentication extends Component {

        componentDidMount() {
            console.log(this.props.isLoginTokenValid)
            if (this.props.isLoginTokenValid === undefined) {

                // Entry from banner / quickActions
                this.props.checkIfLoggedIn((account) => {


                });
            }


        }


        render() {

            return <ComposedComponent {...this.props} />

        }
    }

    const mapStateToProps = (state) => {
        return {
            isLoginTokenValid: state.authentication.isLoginTokenValid,
            currentUser: state.authentication.currentUser
        };
    }
    const mapDispatchToProps = (dispatch) => {

        return {
            checkIfLoggedIn: (token) => dispatch(checkIfLoggedIn(token)),
            updateSelectedTicketsInfo: (playerInfo, index) => updateSelectedTicketsInfo((playerInfo, index))
        }

    }
    return connect(mapStateToProps, mapDispatchToProps)(Authentication);
}
