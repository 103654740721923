import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const useStyles = makeStyles((theme) => ({
    toggleContainer: {
        margin: theme.spacing(2, 0),
    },
}));

const ToggleButtonComponent = ({ yesLabel, noLabel, onSelect, defaultVal, value }) => {
    const [alignment, setAlignment] = React.useState(value);

    useEffect(() => {
        setAlignment(value)
    }, [value])

    const handleAlignment = (event, newAlignment) => {

        setAlignment(newAlignment);

        onSelect(newAlignment)

    };

    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
                <div className={classes.toggleContainer}>
                    <ToggleButtonGroup
                        value={alignment}
                        exclusive
                        onChange={handleAlignment}
                        aria-label="text alignment"
                    >
                        <ToggleButton value={false} aria-label="yes">
                            <div>{yesLabel}</div>
                        </ToggleButton>
                        <ToggleButton value={true} aria-label="no">
                            <div>{noLabel}</div>
                        </ToggleButton>

                    </ToggleButtonGroup>
                </div>
            </Grid>

        </Grid>
    );
}

export default ToggleButtonComponent