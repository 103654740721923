import { dispatchErrorWrap, dispatchSuccessWrap } from './genericActions'

import { actionTypes } from '../types'
import { constants } from '../../App.constants';
import moment from 'moment'
const axios = require('axios');

export const getUpcomingDates = (callback) => {

    return async (dispatch) => {
        let header = {
            headers: {
                'Content-Type': 'application/json',
                'Api-version': 3.4
            }
        }
        try {
            // Not needed as there is no desc displayed from calendar
            // const payload = await axios.get(constants.apiUrl + "/calendar?calendarId=" + constants.selectedCalendar, header);

            //if (payload.data) {

            const eventsPayload = await axios.get(constants.apiUrl + "/calendars/" + constants.selectedCalendar + "/events?fields=['tags']&showDeleted=false&timeMin=" + new Date(moment().startOf('day')).toISOString() + '&timeMax=' + new Date(moment().add(7, 'days').endOf('day').toISOString()), header);
            if (eventsPayload.data && eventsPayload.data.items) {
                dispatchSuccessWrap(actionTypes.GET_UPCOMING_DATES, { events: eventsPayload.data.items }, dispatch);
                callback(null, eventsPayload.data.items)
            } else {
                callback('Error fetching days')
            }
            //}


        } catch (err) {
            console.log(err)
            dispatchErrorWrap(actionTypes.ERROR, dispatch);

        }

    }

}

export const setDayIndex = (index, cb) => {
    return (dispatch) => {
        dispatchSuccessWrap(actionTypes.UPDATE_DAY_INDEX, { index: index }, dispatch)
        cb();
    }
}

export const getTeeTimePostingsMeta = (eventId, filterSpecials, cb) => {
    return async (dispatch) => {
        let header = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        try {
            const payload = await axios.get(constants.ticketsBaseUrl + "/event/" + eventId + "/ticketMeta", header);

            if (payload.data && payload.data.meta) {
                if (filterSpecials) {
                    payload.data.meta = payload.data.meta.filter((meta, index) => meta.markAsSpecial)
                }
                dispatchSuccessWrap(actionTypes.TEE_POSTINGS, { meta: payload.data.meta }, dispatch);
                cb()
            } else {
                dispatchSuccessWrap(actionTypes.TEE_POSTINGS_NONE, { meta: payload.data }, dispatch);
                cb()
            }


        } catch (err) {

            dispatchErrorWrap(actionTypes.ERROR, dispatch);
            cb()

        }

    }
}

